import { AxiosResponse } from "axios";
import api from "@wi-config/axios";

import { PaginationResponse } from "../../pagination";
import SearchCountriesRequest from "../@types/search-countries/SearchCountriesRequest";
import SearchCountriesResponse from "../@types/search-countries/SearchCountriesResponse";
import SearchStatesRequest from "../@types/search-states/SearchStatesRequest";
import SearchStatesResponse from "../@types/search-states/SearchStatesResponse";
import SearchCitiesRequest from "../@types/search-cities/SearchCitiesRequest";
import SearchCitiesResponse from "../@types/search-cities/SearchCitiesResponse";
import SearchLocalizationByZipCodeRequest from "../@types/search-localization-by-zip-code/SearchLocalizationByZipCodeRequest";
import SearchLocalizationByZipCodeResponse from "../@types/search-localization-by-zip-code/SearchLocalizationByZipCodeResponse";



const END_POINT: String = 'api/v1/administration';

const AddressApi = {

    searchCountries: (query?: SearchCountriesRequest): Promise<AxiosResponse<PaginationResponse<SearchCountriesResponse>>> => {
        return api.get(`${END_POINT}/countries`, {params: query});
    },

    searchStates: (query?: SearchStatesRequest): Promise<AxiosResponse<PaginationResponse<SearchStatesResponse>>> => {
        return api.get(`${END_POINT}/states`, {params: query});
    },

    searchCities: (query?: SearchCitiesRequest): Promise<AxiosResponse<PaginationResponse<SearchCitiesResponse>>> => {
        return api.get(`${END_POINT}/cities`, {params: query});
    },

    searchLocalizationByZipCode: (query?: SearchLocalizationByZipCodeRequest): Promise<AxiosResponse<SearchLocalizationByZipCodeResponse>> => {
        return api.get(`${END_POINT}/addresses`, {params: query});
    }

}

export default AddressApi;