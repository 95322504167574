import { useContext } from "react";

import WiPermissionsContext from "../../context/WiPermissionsContext";


const useWiPermissionContext = () => {

    const context = useContext(WiPermissionsContext);

    if (context === undefined) {
        throw new Error('useWiPermissionContext must be used within a WiPermissionProvider');
    }

    return context;
}

export default useWiPermissionContext;