//LIBS
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { ReservationStatus, SearchReservationResponse, reservationExceptionCodes, useReservationSearchQuery } from '@wi-core/shared-blocks';
import { useI18n, useTable, useWiPermissionContext } from '@wi-core/ui';

//TYPES
import { DialogReservationType, ReservationFilterType } from '../../../@types';

//CONFIG
import defaultValues from '../config/DefaultValues';

const PATH_SCHEDULE = "/schedule/new-appointment";

const useReservationList = (onClose: VoidFunction) => {
    const { translate } = useI18n();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { getCurrentUserForAutoComplete, getPermissionLicenseForAutoComplete, getPermissionOrganizationalUnitForAutoComplete } = useWiPermissionContext();

    defaultValues.user = getCurrentUserForAutoComplete();
    defaultValues.license = getPermissionLicenseForAutoComplete();
    defaultValues.organizationalUnits = getPermissionOrganizationalUnitForAutoComplete();
    defaultValues.statuses = [{
        id: ReservationStatus.Reservation,
        label: translate(`common.reservation_status.${ReservationStatus.Reservation}`)
    }];

    const [fullScreen, setFullScreen] = useState(true);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [dialogType, setDialogType] = useState<DialogReservationType>(DialogReservationType.Close);
    const [reservationSelected, setReservationSelected] = useState<SearchReservationResponse | null>(null);
    const { filter, onSearch, onSort, onChangePage, onChangeRowsPerPage } = useTable({
        ...defaultValues
    });

    const { data: table, isLoading, isError, error, isRefetching, refetch } = useReservationSearchQuery(filter);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar(translate(reservationExceptionCodes[error.code] || 'common.error.list_error'), {
                variant: 'error'
            });
        }
    }, [isError]);

    const handleFilter = (value: ReservationFilterType) => {
        onSearch({ ...value });
        refetch();
    };

    const handleChangeFullScreen = () => {
        setFullScreen(!fullScreen);
    };

    const handleShowFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleTransfer = (reservation: SearchReservationResponse) => {
        setDialogType(DialogReservationType.Transfer);
        setReservationSelected(reservation);
    };

    const handleCancel = (reservation: SearchReservationResponse) => {
        setDialogType(DialogReservationType.Cancel);
        setReservationSelected(reservation);
    };

    const handleCloseInternalDialog = () => {
        setDialogType(DialogReservationType.Close);
        setReservationSelected(null);
    };

    const handleClose = (onClose: () => void) => {
        onClose();
    }

    const handleScheduler = (reservation: SearchReservationResponse) => {
        onClose();
        navigate(PATH_SCHEDULE.concat(`?reserveId=${reservation.id}&organizationalUnitKey=${reservation.organizationalUnitKey}`));
    }

    const handleHistory = (reservation: SearchReservationResponse) => {
        setDialogType(DialogReservationType.History);
        setReservationSelected(reservation);
    }


    return {
        filter,
        fullScreen,
        defaultValues,
        showFilter,
        dialogType,
        reservationSelected,
        table, 
        isLoading,
        isRefetching,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
        handleFilter,
        handleChangeFullScreen,
        handleShowFilter,
        handleTransfer,
        handleCancel,
        handleCloseInternalDialog,
        handleClose,
        handleScheduler,
        handleHistory
    };
};

export default useReservationList;
