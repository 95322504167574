import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useI18n } from '@wi-core/ui';
import { ReservationTransferRequest, reservationExceptionCodes } from '@wi-core/shared-blocks';
import { ReservationTransferFormType } from '../../@types';
import { useTransferReservation } from '@wi-core/shared-blocks/src/resources/reservation/hooks/useTransferReservation';
import i18n from '../../i18n';

const userReservationTransferManager = (onClose: VoidFunction) => {
    const { translate, lang } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const transferMutation = useTransferReservation();

    const [loadingManager, setLoadingManager] = useState<boolean>(false);

    const onSubmit = (reservationId: string, organizationalUnitKey: string, data: ReservationTransferFormType) => {
        setLoadingManager(true);
        transfer(reservationId, organizationalUnitKey, data);
    };

    const transfer = async (reservationId: string, organizationalUnitKey: string, data: ReservationTransferFormType) => {
        const reservationTransferRequest: ReservationTransferRequest = {
            id: reservationId,
            justification: data.justification,
            toUserId: data.user ? data.user.id : ''
        };

        try{
            await transferMutation.mutateAsync({organizationalUnitKey: organizationalUnitKey, id: reservationId, data: reservationTransferRequest});
            enqueueSnackbar(i18n[lang.language].success_transfering);
            setLoadingManager(false);
            onClose();            
        }
        catch (error: any) {
            enqueueSnackbar(translate(reservationExceptionCodes[error.code] || i18n[lang.language].error.error_transfering), { variant: 'error' });
            setLoadingManager(false);
        }
    };

    return {
        onSubmit,
        loadingManager
    };
};

export default userReservationTransferManager;
