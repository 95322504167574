import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import WiCircularLoadingProps from "./@types/WiCircularLoadingProps";


const WiCircularLoading = (props: WiCircularLoadingProps) => {

    const {
        show,
        hideBorderRadius,
        message,
        sx
    } = props;

    return (
        <Box
            className="box-circular-loading"
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                backgroundColor: '#f7f8fca1',
                justifyContent: 'center',
                alignItems: 'center',
                visibility: show ? 'visible' : 'hidden',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                borderRadius: hideBorderRadius ? 0 : '16px',
                ...sx
            }}
        >
            <Stack direction="column" alignItems="center" spacing={5}>
                <CircularProgress className="circular-loading" />

                {message && <Typography variant="h6" color="primary">
                    {message}
                </Typography>}
            </Stack>
        </Box>
    );
}

export default WiCircularLoading;
