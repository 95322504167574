import { ReactNode } from 'react';
// form
import { FormProvider as Form } from 'react-hook-form';
import FormProviderProps from './types/FormProviderProps';

// ----------------------------------------------------------------------

const FormProvider: React.FC<FormProviderProps> = (props) => {

    const { 
        id,
        children, 
        onSubmit, 
        methods,
    } = props;

    return (
        <Form {...methods}>
            <form id={id} onSubmit={onSubmit} noValidate>{children}</form>
        </Form>
    );
}


export default FormProvider;
