import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useDateTimeTz } from "@wi-themes/minimal-override";

import WiLabelOffsetProps from "./@types/WiLabelOffsetProps";
import useI18n from "../../hooks/useI18n";


const WiLabelOffset = (props: WiLabelOffsetProps) => {

    const {
        children,
        variant,
        offset,
        sx
    } = props;

    const { translate } = useI18n();
    const { getLocalOffset } = useDateTimeTz();
    const localOffset = getLocalOffset();

    if (!offset || localOffset === offset) {
        return (
            <Stack direction="row" spacing={0.5} sx={{...sx}}>
                <Typography variant={variant}>
                    {children}:
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack direction="row" spacing={0.5} sx={{...sx}}>
            <Typography variant={variant}>
                {children}
            </Typography>
            <Tooltip title={translate('common.time_zone')} arrow placement="top">
                <Typography variant={variant}>
                    ({offset}):
                </Typography>
            </Tooltip>
        </Stack>
    )
}

export default WiLabelOffset;