import { useEffect, useState } from "react";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";
import { useI18n } from "@wi-core/ui";
import { useSnackbar } from "notistack";

import { PaginationDefaultValues } from "../../pagination";
import ProfessionalApi from "../api/ProfessionalApi";
import SearchProfessionalRequest from "../@types/search-professional/SearchProfessionalRequest";
import SearchProfessionalResponse from "../@types/search-professional/SearchProfessionalResponse";


const useProfessional = (firstAutomaticSearch: boolean, onlyAvailableForAppointment: boolean) => {

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [professionals, setProfessionals] = useState<SearchProfessionalResponse[]>([]);
    const [professionalsToAutocomplete, setProfessionalsToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [loadingProfessionals, setLoadingProfessionals] = useState<boolean>(false);
    const [filterByName, setFilterByName] = useState<string>('');
    const [filterByLicenseKey, setFilterByLicenseKey] = useState<string | undefined>();

    useEffect(() => {
        if(firstAutomaticSearch === false) return;

        searchProfessionals({
            keyword: filterByName,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            onlyAvailableForAppointment: onlyAvailableForAppointment,
            licensesKey: filterByLicenseKey
        });
    }, []);

    const searchProfessionals = (search: SearchProfessionalRequest) => {
        setLoadingProfessionals(true);

        ProfessionalApi.search(search)
            .then((result) => {

                const { data: { content } } = result;

                setProfessionals(content);
                setProfessionalsToAutocomplete(content.map(e => {
                    return {
                        id: e.id,
                        label: `${e.firstName} ${e.lastName}`,
                        data: e
                    }
                }));
            })
            .catch((error) => {
                console.error(error);
                setProfessionals([]);
                setProfessionalsToAutocomplete([]);

                enqueueSnackbar(
                    translate('common.search_error.error_loading_professionals'),
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setLoadingProfessionals(false);
            })
    }

    const handleFilterProfessionalsByName = (value: string) => {
        setFilterByName(value);

        const search: SearchProfessionalRequest = {
            keyword: value,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            onlyAvailableForAppointment: onlyAvailableForAppointment,
            licensesKey: filterByLicenseKey
        };

        searchProfessionals(search);
    }

    const handleFilterProfessionalsByLicenseKey = (newLicenseKey?: string) => {
        setFilterByLicenseKey(newLicenseKey);

        const search: SearchProfessionalRequest = {
            keyword: filterByName,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            onlyAvailableForAppointment: onlyAvailableForAppointment,
            licensesKey: newLicenseKey
        };

        searchProfessionals(search);
    }

    const setCurrentLicenseKey = (newLicenseKey?: string) => {
        setFilterByLicenseKey(newLicenseKey);
    }

    return {
        professionals,
        professionalsToAutocomplete,
        loadingProfessionals,
        handleFilterProfessionalsByName,
        handleFilterProfessionalsByLicenseKey,
        setCurrentLicenseKey
    }
}

export default useProfessional;
