import { useMutation, useQueryClient } from "@tanstack/react-query";
import ReservationApi from "../api/ReservationApi";
import { ReservationTransferRequest } from "../@types";
import { CommonCreateUpdateResponse } from "../../common-create-update-response";

export interface TransferReservationRequest {
    organizationalUnitKey: string,
    id: string,
    data: ReservationTransferRequest
}

export const useTransferReservation = () => {

    const queryClient = useQueryClient();

    return useMutation<CommonCreateUpdateResponse, unknown, TransferReservationRequest, unknown>(async (request) => {

        var response = await ReservationApi.transfer(request.id, request.organizationalUnitKey, request.data)

        return response.data;
    },
        {
            onSuccess: async (result, variables, context) => {
                await queryClient.invalidateQueries(['useReservationByFilterQuery'])
            }
        }
    );
}
