// @mui
import {
    Box,
    Checkbox,
    TableRow,
    TableCell,
    TableHead,
    TableSortLabel,
} from '@mui/material';
import WiTableHeadCustomProps from './types/WiTableHeadCustomProps';

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
} as const;

const WiTableHeadCustom = ({
    order,
    orderBy,
    rowCount = 0,
    headLabel,
    onSort,
    onSelectAllRows,
    selectedAllRows,
    sx,
    disabledCheckbox,
}: WiTableHeadCustomProps) => (
    <TableHead sx={sx}>
        <TableRow>
            {onSelectAllRows && (
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={disabledCheckbox}
                        checked={selectedAllRows}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            onSelectAllRows(event.target.checked)
                        }
                    />
                </TableCell>
            )}

            {headLabel.map((headCell) => (
                !headCell.hidden && <TableCell
                    key={headCell.id}
                    align={headCell.align || 'left'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ width: headCell.width, minWidth: headCell.minWidth }}
                >
                    {onSort && !headCell.hideSort ? (
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => onSort(headCell.id)}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {headCell.label}

                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    ) : (
                        headCell.label
                    )}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

export default WiTableHeadCustom;
