import { useState } from 'react';
import { SearchReservationRequest } from '../../@types';
import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { PaginationResponse } from '../../../pagination';
import SearchReservationResponse from '../../@types/search-reservation/SearchReservationResponse';
import ReservationApi from '../../api/ReservationApi';
import { WiAutocompleteOption, WiSelectTagsOption, useFormatDateTime } from '@wi-themes/minimal-override';
import { SearchFilter } from '@wi-core/ui';
import { ErrorDetail } from '../../../error';

export const useReservationSearchQuery = (filter: SearchFilter): UseQueryResult<AxiosResponse<PaginationResponse<SearchReservationResponse>, ErrorDetail>, ErrorDetail> => {
    const { fDateTimeStartDay, fDateTimeEndDay, fDateIgnoringTimezone } = useFormatDateTime();

    if (!filter.startAt) {
        return useQuery(['useReservationByFilterQuery', filter], async ({ signal }) => {
            return {
                data: {
                    content:[],
                    totalElements: 0,
                }
            }
        });
    }
    
    const search: SearchReservationRequest = {
        page: filter?.page || 0,
        size: filter?.rowsPerPage || 10,
        sort: `startAt,asc`,
        licenseKey: filter?.license ? filter?.license.id : '',
        startAt: filter?.startAt ? fDateTimeStartDay(filter.startAt).toJSON() : null,
        endAt: filter?.endAt ? fDateTimeEndDay(filter.endAt).toJSON() : null,
        organizationalUnitsKey: filter?.organizationalUnits
            ? filter.organizationalUnits
                .map((organizationalUnit: WiAutocompleteOption) => organizationalUnit.id)
                .join(',')
            : undefined,
        professionalId: filter?.professional?.id || undefined,
        personName: filter?.personName,
        personBirthDate: fDateIgnoringTimezone(filter?.personBirthDate),
        personDocument: filter?.personDocument,
        statuses: filter?.statuses
            ? filter.statuses.map((status: WiAutocompleteOption) => status.id).join(',')
            : undefined,
        roomId: filter?.room?.id || undefined,
        procedureName: filter?.procedureName,
        tagsId: filter?.tags ? filter.tags.map((tag: WiSelectTagsOption) => tag.id).join(',') : undefined,
        username: filter?.user?.data?.username || undefined,
        ids: filter.ids || []
    };


    return useQuery(['useReservationByFilterQuery', filter], async ({ signal }) => {
        return ReservationApi.search(search, signal);
    });

};
