import { AxiosResponse } from "axios";
import api from "@wi-config/axios";

import { PaginationResponse } from "../../pagination";
import { 
    GetHealthPlanResponse, 
    ListAvailableHealthPlanForDisponibilityRequest, 
    ListAvailableHealthPlanForDisponibilityResponse, 
    ListHealthPlanOrganizationalUnitProcedureByHealthPlanRequest, 
    ListHealthPlanOrganizationalUnitProcedureByHealthPlanResponse, 
    ListHealthPlanOrganizationalUnitProcedureRequest, 
    ListHealthPlanOrganizationalUnitProcedureResponse, 
    ListHealthPlanResponse, 
    SearchHealthPlanRequest, 
    SearchHealthPlanResponse 
} from "../@types";


const PATH_HEALTH_PLAN: string = 'api/v1/planning/health-plans';
const PATH_SCHEDULE: string = 'api/v1/schedule';

const HealthPlanApi = {
    search: (data?: SearchHealthPlanRequest): Promise<AxiosResponse<PaginationResponse<SearchHealthPlanResponse>>> => {
        return api.get(`${PATH_HEALTH_PLAN}/search`, { params: data });
    },
    searchByPersonId: (personId: string): Promise<AxiosResponse<{ healthCards: SearchHealthPlanResponse[] }>> => {
        return api.get(`${PATH_HEALTH_PLAN}/${personId}/health-plan`);
    },
    organizationalUnitProcedures: (healthPlanId: string, data: ListHealthPlanOrganizationalUnitProcedureRequest): Promise<AxiosResponse<ListHealthPlanOrganizationalUnitProcedureResponse>> => {
        return api.get(`${PATH_HEALTH_PLAN}/${healthPlanId}/organizational-unit-procedures/list`, { params: data });
    },
    list: (ids: string[]): Promise<AxiosResponse<{healthPlanList: ListHealthPlanResponse[]}>> => {
        return api.get(`${PATH_HEALTH_PLAN}/list`, { params: { ids: ids.join('') } });
    },
    get: (id: string): Promise<AxiosResponse<GetHealthPlanResponse>> => {
        return api.get(`${PATH_HEALTH_PLAN}/${id}`);
    },
    listAvailableHealthPlanForDisponibility: (
        data: ListAvailableHealthPlanForDisponibilityRequest
    ): Promise<AxiosResponse<{healthPlanList: ListAvailableHealthPlanForDisponibilityResponse[]}>> => {
        return api.get(`${PATH_SCHEDULE}/available-health-plan-for-disponibility`, { params: data });
    },
    listHealthPlanOrganizationalUnitProcedureByHealthPlan: (
        data: ListHealthPlanOrganizationalUnitProcedureByHealthPlanRequest
    ): Promise<AxiosResponse<ListHealthPlanOrganizationalUnitProcedureByHealthPlanResponse>> => {
        return api.post(`${PATH_HEALTH_PLAN}/procedures-by-health-plan`, data);
    }
};

export default HealthPlanApi;