import React from 'react';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TablePagination,
} from '@mui/material';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import useI18n from '../../../../hooks/useI18n';
import DialogReservationListProps from './@types/DialogReservationListProps';
import WiCircularLoading from '../../../wi-circular-loading/WiCircularLoading';
import Scrollbar from '@wi-themes/minimal/src/components/Scrollbar';
import { WiEmptyContent } from '@wi-themes/minimal-override';
import useReservationList from './hooks/useReservationList';
import ReservationFilter from './components/reservation-filter/ReservationFilter';
import IsVisible from '../../../is-visible/IsVisible';
import WiReservationInfo from '../../../wi-reservation-info/WiReservationInfo';
import i18n from '../../i18n';
import { DialogReservationType } from '../../@types';
import WiDialogReservationCancel from '../../../wi-dialog-reservation-cancel/WiDialogReservationCancel';
import WiDialogReservationTransfer from '../../../wi-dialog-reservation-transfer/WiDialogReservationTransfer';
import WiDialogReservationHistory from '../../../wi-dialog-reservation-history/WiDialogReservationHistory';

const DialogReservationList = (props: DialogReservationListProps) => {
    const { open, onClose } = props;

    const { translate, lang } = useI18n();

    const {
        filter,
        fullScreen,
        defaultValues,
        dialogType,
        table,
        isLoading,
        isRefetching,
        reservationSelected,
        onChangePage,
        onChangeRowsPerPage,
        handleFilter,
        handleChangeFullScreen,
        handleTransfer,
        handleCancel,
        handleCloseInternalDialog,
        handleClose,
        handleScheduler,
        handleHistory
    } = useReservationList(onClose);

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            disableEscapeKeyDown
            fullWidth
            maxWidth="xl"
            PaperProps={{
                sx: {
                    minHeight: {
                        lg: 'calc(100% - 150px)',
                        md: 'calc(100% - 50px)',
                        sm: 'calc(100% - 50px)',
                        xs: 'calc(100% - 50px)'
                    }
                }
            }}
        >
            <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    {i18n[lang.language].availability_reservations}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={handleChangeFullScreen}>
                            <Iconify icon={fullScreen ? 'mdi:fullscreen-exit' : 'mdi:fullscreen'} />
                        </IconButton>
                        <IconButton onClick={onClose}>
                            <Iconify icon={'eva:close-fill'} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent
                sx={{
                    p: 3,
                    pt: '24px !important',
                    bgcolor: theme => theme.palette.grey[500_12]
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 2
                    }}
                >
                    <ReservationFilter handleFilter={handleFilter} initialFilter={{ ...defaultValues }} />
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}
                    >
                        <Box
                            sx={{
                                minHeight: '139px',
                                position: 'relative'
                            }}
                        >
                            <WiCircularLoading show={isLoading || isRefetching} hideBorderRadius />
                            <IsVisible when={!!table && table.data.content.length > 0}>
                                <Scrollbar>
                                    <Stack direction="column" divider={<Divider />}>
                                        {table?.data.content.map(value => (
                                            <WiReservationInfo
                                                key={value.id}
                                                row={value}
                                                onTransfer={() => handleTransfer(value)}
                                                onCancel={() => handleCancel(value)}
                                                onSchedule={() => handleScheduler(value)}
                                                onHistory={() => handleHistory(value)}
                                            />
                                        ))}
                                    </Stack>
                                </Scrollbar>
                            </IsVisible>
                            <IsVisible when={(!isLoading && !table) || table?.data.totalElements == 0}>
                                <WiEmptyContent
                                    title={translate('appointment.list_mensage.no_data')}
                                    sx={{
                                        py: 3,
                                        px: 2,
                                        '& span.MuiBox-root': { height: 120 }
                                    }}
                                />
                            </IsVisible>
                        </Box>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={table?.data.totalElements || 0}
                            rowsPerPage={filter?.rowsPerPage || 10}
                            page={filter?.page || 0}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />
                    </Card>
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button color="inherit" variant="outlined" onClick={() => handleClose(onClose)}>
                    {translate('common.close')}
                </Button>
            </DialogActions>

            <IsVisible when={dialogType === DialogReservationType.Transfer}>
                <WiDialogReservationTransfer
                    open={dialogType === DialogReservationType.Transfer}
                    onClose={handleCloseInternalDialog}
                    reservation={reservationSelected}
                />
            </IsVisible>

            <IsVisible when={dialogType === DialogReservationType.Cancel} >
                <WiDialogReservationCancel
                    open={dialogType === DialogReservationType.Cancel}
                    onClose={handleCloseInternalDialog}
                    reservation={reservationSelected}
                />
            </IsVisible>

            <IsVisible when={dialogType === DialogReservationType.History} >
                <WiDialogReservationHistory
                    open={dialogType === DialogReservationType.History}
                    onClose={handleCloseInternalDialog}
                    reservation={reservationSelected}
                />
            </IsVisible>

        </Dialog>
    );
};

export default DialogReservationList;
