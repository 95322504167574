const useFormatString = () => {

    // const fName2 = (name: string) => {
    //     return name.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letra => letra.toUpperCase()).trim();
    // }

    const fName = (name: string) => {
        let conjuctions = ["e", "y", "de", "la", "las", "lo", "los", "do", "dos", "da", "das", "del", "van", "von", "bin", "le"]

        return name.toLowerCase().split(" ").map( word => {
            if(!conjuctions.includes(word))
                word = word.charAt(0).toUpperCase() + word.slice(1)
            return word;

        }).join(' ')
    }

    return {
        fName
    }
}

export default useFormatString;
