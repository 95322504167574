// @mui
import { TableRow, TableCell } from '@mui/material';
import { useI18n } from '@wi-core/ui';
import WiEmptyContent from '../../../wi-empty-content/WiEmptyContent';

//types
import WiTableNoDataProps from './types/WiTableNoDataProps';
import i18n from './i18n';

const WiTableNoData = (props: WiTableNoDataProps) => {

    const {
        isNotFound,
        colSpan,
        title,
        padding,
        height
    } = props;

    const { lang } = useI18n();

    return (
        <>
            {isNotFound && <TableRow>
                <TableCell colSpan={colSpan}>
                    <WiEmptyContent
                        title={title || i18n[lang.language].no_data}
                        sx={{
                            '& span.MuiBox-root': { 
                                height: height || 160
                            },
                            p: padding || '64px 16px'
                        }}
                    />
                </TableCell>
            </TableRow>}
        </>
    );
}

export default WiTableNoData;
