import { AxiosResponse } from "axios";
import api from "@wi-config/axios";

import ListOrganizationalUnitsByRolesRequest from "../@types/list-organizational-units-by-roles/ListOrganizationalUnitsByRolesRequest";
import ListOrganizationalUnitsByRolesResponse from "../@types/list-organizational-units-by-roles/ListOrganizationalUnitsByRolesResponse";


const PATH_ORGANIZATIONAL_UNIT: String = 'api/v1/administration/organizational-units';

const OrganizationalUnitApi = { 

    listByRoles: (data?: ListOrganizationalUnitsByRolesRequest): Promise<AxiosResponse<ListOrganizationalUnitsByRolesResponse>> => {
        return api.get(`${PATH_ORGANIZATIONAL_UNIT}/by-roles`, {params: data});
    }

};

export default OrganizationalUnitApi;