import React from "react";
import { useFormContext } from "react-hook-form";
import { Box, FormHelperText } from "@mui/material";
import { useI18n } from "@wi-core/ui";

import { useFormMode } from "../../../hooks";
import { WiAutocompleteMultipleNoForm, WiAutocompleteNoForm, WiAutocompleteOption } from "../../wi-inputs-no-form";

import WiAutocompleteMultipleProps from "./types/WiAutocompleteMultipleProps";


const WiAutocompleteMultiple = (props: WiAutocompleteMultipleProps) => {

    const {
        name,
        label,
        loading,
        required,
        disabled,
        options,
        placeholder,
        onInputChange,
        sx,
    } = props;

    const { getValues, setValue, getFieldState, formState, trigger } = useFormContext();
    const { isView } = useFormMode();
    const { translate } = useI18n();

    const fieldState = getFieldState(name, formState);
    const currentValue = getValues(name);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
            <WiAutocompleteMultipleNoForm
                label={label}
                error={!!fieldState.error}
                value={currentValue}
                options={options}
                loading={loading}
                required={required}
                disabled={disabled || isView}
                readOnly={disabled || isView}
                placeholder={placeholder}
                onChange={(values: WiAutocompleteOption[]) => {
                    setValue(name, values);
                    trigger(name);
                }}
                onInputChange={onInputChange}
            />

            <FormHelperText error sx={{ m: '4px 8px 0 8px', minHeight: '8px' }}>
                {translate(fieldState.error?.message || '')}
            </FormHelperText>
        </Box>
    );
}

export default WiAutocompleteMultiple;
