//LIBS
import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import IconButtonAnimate from '@wi-themes/minimal/src/components/animate/IconButtonAnimate';
import Iconify from '@wi-themes/minimal/src/components/Iconify';

// COMPONENTS
import DialogReservationList from './components/dialog-reservation-list/DialogReservationList';
import IsVisible from '../is-visible/IsVisible';
import useI18n from '../../hooks/useI18n';
import i18n from './i18n';

const WiReservationListButton = () => {
    const [open, setOpen] = useState<boolean>(false);
    const {lang} = useI18n();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title={i18n[lang.language].availability_reservations}>
                <IconButtonAnimate
                    onClick={handleOpen}
                    sx={{
                        width: 40,
                        height: 40,
                        ...(open && { bgcolor: 'action.selected' })
                    }}
                    color="primary"
                >
                    <Iconify icon={'fluent:text-bullet-list-square-clock-20-regular'} />
                </IconButtonAnimate>
            </Tooltip>
            <IsVisible when={open}>
                <DialogReservationList open={open} onClose={handleClose} />
            </IsVisible>
        </>
    );
};

export default WiReservationListButton;
