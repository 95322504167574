//LIBS
import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from '@mui/material';
import {
    Timeline,
    TimelineConnector,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from '@mui/lab';
import { Guid } from 'guid-typescript';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import Scrollbar from '@wi-themes/minimal/src/components/Scrollbar';
import DialogReservationHistoryProps from './@types/dialog-reservation-history-props/DialogReservationHistoryProps';
import { useFormatDateTime } from '@wi-themes/minimal-override';
import { SearchReservationHistory } from '@wi-core/shared-blocks';
import useI18n from '../../hooks/useI18n';
import i18n from './i18n';

//TYPES
import ReservationHistoryTimeineContent from './components/reservation-history-time-line-content/ReservationHistoryTimeineContent';

//convert this const in memoized selector
const TIMELINE_ITEM_ICON = {
    'CREATED': 'eva:plus-fill',
    'TRANSFERED': 'tabler:transfer',
    'CANCELLED': 'prime:times-circle',
    'SCHEDULED': 'tdesign:calendar-2',
    'EXPIRED': 'eva:clock-outline',
}


const WiDialogReservationHistory = (props: DialogReservationHistoryProps) => {
    const { open, onClose, reservation } = props;

    const { translate, lang } = useI18n();

    const {
        fDate,
        fTime
    } = useFormatDateTime();

    const renderTimelineItem = (history: SearchReservationHistory) => {

        if (history.dateTime === null) return;

        return <TimelineItem key={Guid.create().toString()}>
            <TimelineOppositeContent
                sx={{ m: 'auto 0', maxWidth: '90px' }}
                align="right"
                variant="caption"
                color="text.secondary"
            >
                {fDate(history.dateTime)} <br />
                {fTime(history.dateTime)}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                    <Iconify icon={TIMELINE_ITEM_ICON[history.type]} width={20} height={20} />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <ReservationHistoryTimeineContent history={history} />

        </TimelineItem>
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableEscapeKeyDown
            maxWidth="md"
        >
            <Box
                sx={{
                    width: { xs: '100%', md: '750px' },
                    position: 'relative'
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {i18n[lang.language].history}
                        <IconButton onClick={onClose}>
                            <Iconify icon={'eva:close-fill'} />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent
                    sx={{
                        pb: 0,
                        mt: '16px',
                        height: '400px'
                    }}
                >
                    <Scrollbar>
                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 3
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    '& strong': {
                                        color: (theme) => theme.palette.warning.main
                                    }
                                }}
                            >
                                <Timeline>
                                    {reservation?.history?.map((history: SearchReservationHistory) => renderTimelineItem(history))}
                                </Timeline>
                            </Box>
                        </Box>
                    </Scrollbar>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" variant="outlined" onClick={onClose}>
                        {translate("common.close")}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default WiDialogReservationHistory;
