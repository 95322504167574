import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useI18n } from "@wi-core/ui";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";

import AddressApi from "../api/AddressApi";
import SearchCountriesResponse from "../@types/search-countries/SearchCountriesResponse";
import SearchCountriesRequest from "../@types/search-countries/SearchCountriesRequest";


const useCountries = () => {

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [countries, setCountries] = useState<SearchCountriesResponse[]>([]);
    const [countriesToAutocomplete, setCountriesToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [nationalitiesToAutocomplete, setNationalitiesToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
    const [filterByName, setFilterByName] = useState('');

    useEffect(() => {
        searchCountries();
    }, []);

    const searchCountries = (search?: SearchCountriesRequest) => {
        
        setLoadingCountries(true);

        search = search || {
            keyword: filterByName
        }

        AddressApi.searchCountries(search)
            .then(result => {

                const { data: { content } } = result;
                
                setCountries(content);
                setCountriesToAutocomplete(
                    content.map(e => {
                        return {
                            id: e.id,
                            label: e.name,
                            data: e.nationality
                        }
                    })
                );
                setNationalitiesToAutocomplete(
                    content.map(e => {
                        return {
                            id: e.id,
                            label: e.nationality,
                            data: e.name
                        }
                    })
                );
            })
            .catch(() => {
                setCountries([]);
                setCountriesToAutocomplete([]);

                enqueueSnackbar(
                    translate('common.search_error.error_loading_countries'),
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setLoadingCountries(false);
            });
    };

    const handleFilterCountriesByName = (value: string) => {
        setFilterByName(value);

        searchCountries({
            keyword: value
        });
    }

    return {
        countries,
        countriesToAutocomplete,
        nationalitiesToAutocomplete,
        loadingCountries,
        handleFilterCountriesByName
    };
}

export default useCountries;