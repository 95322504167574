import { ExceptionCodes } from "@wi-core/shared-blocks";


const calendarExceptionCodes: ExceptionCodes = {
    "PL UC094 0001": "search_reference.no_permission_organizational_unit",
    "PL UC097 0001": "search_reference.no_permission_organizational_unit",
    "PL UC0100 0001": "search_reference.no_permission_organizational_unit",//Sem permissão na unidade organizacional.
    "PL UC0103 0001": "search_reference.no_permission_organizational_unit",
}

export default {
    ...calendarExceptionCodes
};
