import { Controller, useFormContext } from "react-hook-form";
import { Box, FormHelperText, TextField, TextFieldProps } from "@mui/material";
import DatePicker from '@mui/lab/DatePicker';
import { useI18n } from "@wi-core/ui";

import { useFormMode } from "../../../hooks";
import WiDatePickerProps from "./types/WiDatePickerProps";


const WiDatePicker: React.FC<WiDatePickerProps & TextFieldProps> = (props) => {

    const {
        name,
        minDate,
        maxDate,
        ...other
    } = props;

    const { control } = useFormContext();
    const { isView } = useFormMode();
    const { translate, lang } = useI18n();


    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <DatePicker {...field}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={other.disabled || isView}
                        renderInput={
                            (params) => <TextField
                                {...params}
                                {...field}
                                fullWidth
                                error={!!error}
                                {...other}
                                disabled={other.disabled || isView}
                            />
                        }
                    />
                    <FormHelperText error sx={{ m: '4px 8px 0 8px', minHeight: '8px' }}>
                        {translate(error?.message || '')}
                    </FormHelperText>
                </Box>
            )}
        />
    );
}


export default WiDatePicker;
