import { ExceptionCodes } from "@wi-core/shared-blocks";


const reasonExceptionCodes: ExceptionCodes = {
    //create_reason
    "PL UC148 0001": "create_reason.invalid_license_key_error",
    "PL UC148 0002": "create_reason.invalid_organizational_unit_key_error",
    "PL UC148 0003": "create_reason.invalid_description_error",
    "PL UC148 0004": "create_reason.invalid_type_error",
    "PL UC148 0005": "create_reason.invalid_classification_error",
    "PL UC148 0006": "create_reason.invalid_origin_error",
    "PL UC148 0007": "create_reason.license_authorization_error",
    "PL UC148 0008": "create_reason.organizational_unit_authorization_error",


    //update_reason
    "PL UC149 0001": "update_reason.license_authorization_error",
    "PL UC149 0002": "update_reason.organizational_unit_authorization_error",
    "PL UC149 0003": "update_reason.invalid_description_error",
    "PL UC149 0004": "update_reason.disponibility_reservation_reason_not_found_error",

    //get_reason
    "PL UC150 0002": "get_reason.organizational_unit_authorization_error",
    "PL UC150 0003": "get_reason.disponibility_reservation_reason_not_found_error",

    //search_reason

    //archive_reason
    "PL UC152 0001": "archive_reason.organizational_unit_authorization_error",
    "PL UC152 0002": "archive_reason.license_authorization_error",
    "PL UC152 0003": "archive_reason.disponibility_reservation_reason_not_found_error",
    "PL UC152 0004": "archive_reason.invalid_code_error",
    "PL UC152 0005": "archive_reason.already_archived_error",

    //unarchive_reason
    "PL UC153 0001": "unarchive_reason.organizational_unit_authorization_error",
    "PL UC153 0002": "unarchive_reason.license_authorization_error",
    "PL UC153 0003": "unarchive_reason.disponibility_reservation_reason_not_found_error",
    "PL UC153 0004": "unarchive_reason.invalid_code_error",
    "PL UC153 0005": "unarchive_reason.already_unarchived_error"

}

export default {
    ...reasonExceptionCodes
};
