const useFileHelper = () => {
    const toBase64 = (file: Blob) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(`${reader.result}`);
        reader.onerror = error => reject(error);
    });

    return {
        toBase64
    }
}

export default useFileHelper;
