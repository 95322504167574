import { Box } from "@mui/material";
import { DataEventHistory } from "@wi-core/shared-blocks";
import WiDataEventHistory from "../wi-data-event-history/WiDataEventHistory";
import WiFormCard from "../wi-form-card/WiFormCard";



const HistoryTab = (props: DataEventHistory) => {

    return (
        <WiFormCard 
            sx={{
                p: 3
            }}
            hideArchivedLabel={true}
        >
            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                }}
            >
                <WiDataEventHistory {...props}/>
            </Box>
        </WiFormCard>
    );
}

export default HistoryTab;