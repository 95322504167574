import React, { forwardRef } from "react";
import { useDebounce, useI18n } from "@wi-core/ui";
import { Autocomplete, Box, CircularProgress, Stack, TextField, Typography } from "@mui/material";

import WiTag from "../../wi-tag/WiTag";
import WiSelectTagsOption from "./@types/WiSelectTagsOption";
import WiSelectTagsNoFormProps from "./@types/WiSelectTagsNoFormProps";
import i18n from './i18n';

const WiSelectTagsNoForm = (props: WiSelectTagsNoFormProps) => {

    const {
        value,
        label,
        placeholder,
        loading,
        required,
        disabled,
        error,
        options,
        inputChangeDebounceTime,
        totalOptions,
        onInputChange,
        onChange
    } = props;

    const { debounce } = useDebounce();

    return (
        <Autocomplete
            sx={{ '.MuiOutlinedInput-root': { p: '6px !important' } }}
            size="small"
            multiple
            fullWidth
            value={value}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => onChange(newValue)}
            onInputChange={(event, value) => {
                if (!onInputChange) return;

                debounce(() => {
                    onInputChange(value);
                }, inputChangeDebounceTime || 500);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}
                    sx={{
                        p: '4px !important',
                        m: '4px !important'
                    }}>
                    <WiTag
                        key={option.id}
                        color={option.color}
                        label={option.name}
                    />
                </Box>
            )}
            renderTags={(values: WiSelectTagsOption[], getTagProps) => {
                return values.map((option, index) => (
                    <WiTag
                        key={option.id}
                        color={option.color}
                        label={option.name}
                        sx={{ m: '2px' }}
                    />
                ))
            }}
            options={options}
            loading={loading}
            renderInput={(params) => <TextField
                label={label}
                {...params}
                size="small"
                error={!!error}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                sx={{
                    pointerEvents: disabled ? 'none' : 'auto',
                    '.MuiAutocomplete-input': {
                        minWidth: '20px',
                        marginRight: '56px',
                    }
                }}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: <Box sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: loading ? '10px' : '20px',
                        minWidth: '56px',
                        display: 'flex',
                        flexDirection: 'column',
                        '.MuiAutocomplete-endAdornment': {
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }
                    }}>
                        <Stack flex={1} flexDirection="row" spacing={2} alignItems="center">
                            {loading && <Stack flexDirection="row" alignItems="center">
                                <CircularProgress color="inherit" size={20} />
                            </Stack>}
                            {!disabled && <Stack flexDirection="row" alignItems="center">
                                {params.InputProps.endAdornment}
                            </Stack>}
                        </Stack>
                    </Box>
                }}
            />}
            disabled={disabled}
            ListboxComponent={listboxProps => <ListboxComponent partialTotalOptions={options.length} totalOptions={totalOptions} {...listboxProps}  />}

        />
    );
}

type ListboxComponentProps = {
    totalOptions?: number;
    partialTotalOptions?: number;
}

const ListboxComponent = forwardRef<HTMLUListElement, ListboxComponentProps>(function ListboxComponent({ totalOptions = 0, partialTotalOptions = 0, ...rest }, ref) {
    const { lang } = useI18n();

    return (
        <>
            <ul ref={ref} {...rest} />
            {totalOptions > 0 && <Stack direction="column"
                alignItems={'center'}
                spacing={0.5}
                p={1}
                sx={{ borderTop: '1px solid #eee', boxShadow: '8px 0px 16px 0 #eee' }}>
                <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                        {`${i18n[lang.language].showing} ${partialTotalOptions} ${i18n[lang.language].of} ${totalOptions} ${i18n[lang.language].result}`}
                    </Typography>
                </Stack>
            </Stack>}
        </>
    );
});

export default WiSelectTagsNoForm;
