import { AxiosResponse } from "axios";
import api from "@wi-config/axios";

import { PaginationResponse } from "../../pagination";
import ListCountryDDIResponse from '../@types/ListCountryDDIResponse';

const PATH_TIMEZONE: string = '/api/v1/administration/countries/list-ddi';

const CountryApi = {
    listDdi: (): Promise<AxiosResponse<PaginationResponse<ListCountryDDIResponse>>> => {
        return api.get(`${PATH_TIMEZONE}`);
    }
};

export default CountryApi;
