import { Box, Typography, Stack } from '@mui/material';

import { useI18n } from '@wi-core/ui';

import { UploadIllustration } from '@wi-themes/minimal/src/assets';

import i18n from '../../i18n';

// ----------------------------------------------------------------------

export default function BlockContent() {

    const { lang } = useI18n();

    return (
        <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
            <UploadIllustration sx={{ width: 220 }} />

            <Box sx={{ p: 3 }}>
                <Typography gutterBottom variant="h5">
                    {i18n[lang.language].block_content.title_drop_or_select_file}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {i18n[lang.language].block_content.first_message_drop_files_here_or_click}&nbsp;
                    <Typography
                        variant="body2"
                        component="span"
                        sx={{ color: 'primary.main', textDecoration: 'underline' }}
                    >
                        {i18n[lang.language].block_content.message_browse}
                    </Typography>
                    &nbsp;{i18n[lang.language].block_content.last_message_through_your_machine}
                </Typography>
            </Box>
        </Stack>
    );
}
