import exception_en from "./resources/exception/exception_en.json";
import exception_pt_br from "./resources/exception/exception_pt_br.json";

import roleExceptionCodes from "./resources/exception/exceptionCodes";


export { roleExceptionCodes };


const roleI18n = {
    en: {
        ...exception_en
    },
    pt_br: {
        ...exception_pt_br
    }
};

export default roleI18n;