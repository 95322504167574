import React from "react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { useDebounce } from "@wi-core/ui";

import WiAutocompleteNoFormProps from "./@types/WiAutocompleteNoFormProps";
import WiAutocompleteOption from "./@types/WiAutocompleteOption";


const WiAutocompleteNoForm = (props: WiAutocompleteNoFormProps) => {

    const {
        value,
        label,
        placeholder,
        loading,
        required,
        disabled,
        error,
        options,
        inputChangeDebounceTime,
        ref,
        sx,
        classNamePopper,
        onInputChange,
        onChange,
        renderOption,
        filterOptions,
        getOptionLabel
    } = props;

    const { debounce } = useDebounce();

    const defaultRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: WiAutocompleteOption) => (
        <Box component="li" {...props} key={option.id}>
            {option.label}
        </Box>
    )

    return (
        <Autocomplete
            sx={{ '.MuiOutlinedInput-root': { p: '6px !important' }, ...sx }}
            ref={ref}
            size="small"
            fullWidth
            value={value}
            renderOption={!renderOption ? defaultRenderOption : renderOption}
            options={options}
            loading={loading}
            disabled={disabled}
            filterOptions={filterOptions}
            classes={{ popper: classNamePopper || '' }}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            getOptionLabel={option => {
                return getOptionLabel?.(option) || option.label;
            }}
            getOptionDisabled={option => {
                return option.disabled === true;
            }}
            onChange={(event, value) => onChange(value)}
            onInputChange={(event, value) => {
                if (!onInputChange) return;

                debounce(() => {
                    onInputChange(value);
                }, inputChangeDebounceTime || 500);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    error={!!error}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    sx={{
                        pointerEvents: disabled ? 'none' : 'auto'
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mr: '4px'
                                        }}
                                    >
                                        <CircularProgress color="inherit" size={20} />
                                    </Box>
                                )}
                                {!disabled && (
                                    <Box sx={{ '&>div': { position: 'static' } }}>{params.InputProps.endAdornment}</Box>
                                )}
                            </>
                        )
                    }}
                />
            )}
        />
    );
}

export default WiAutocompleteNoForm
