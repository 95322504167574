import IsVisibleWithRole from '../is-visible-with-role/IsVisibleWithRole';
import IsVisibleProps from './@types/IsVisibleProps';

const IsVisible = (props: IsVisibleProps) => {
    const { children, fallback = null, when = false } = props;

    if (when) {
        return children;
    }

    return fallback;
};

export default IsVisible;
