import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import { WiHeaderOnlyLayout, WiLogoOnlyLayout } from '@wi-themes/minimal-override';

import LoadingScreen from '@wi-themes/minimal/src/components/LoadingScreen';

const Loadable = (Component: React.FC<any>) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();

	return (
		<Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/portal')} />}>
			<Component {...props} />
		</Suspense>
	);
};

const Routes = () => {
	return useRoutes([
		{
			path: 'portal',
			element: <WiHeaderOnlyLayout />,
			children: [
				{ path: '', element: <PortalPage />, index: true }
			],
		},
		{
            path: '*',
            element: <WiLogoOnlyLayout />,
            children: [
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> }
	]);
}

export default Routes;

const PortalPage = Loadable(lazy(() => import('../features/portal/PortalPage')));
const NotFound = Loadable(lazy(() => import('@wi-themes/minimal-override/src/pages/wi-page-404/WiPage404')));


