import { WebStorageStateStore } from 'oidc-client-ts';

import EnvironmentConfig from './types/EnvironmentConfig';
import EnvironmentType from './types/EnvironmentType';

const Environment: EnvironmentConfig = {
    type: EnvironmentType.Production,
    oidcConfig: {
        authority: 'https://wecare-demo.weinnovate.com.br:8086/realms/weinnovate',
        client_id: 'wi-frontend',
        redirect_uri: 'https://wecare-demo.weinnovate.com.br/portal',
        post_logout_redirect_uri: 'https://wecare-demo.weinnovate.com.br/login',
        userStore: new WebStorageStateStore({ store: localStorage }),
        onSigninCallback: (): void => {
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname
            )
        }
    },
    loginPath: '/login',
    portalPath: '/portal',
    endpoint: 'https://wecare-demo.weinnovate.com.br:8766',
    fullCalendarLicenseKey: '0204466063-fcs-1678379458',
    version: '1.8.35.4'
};

export default Environment;
