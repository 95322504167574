import { useTheme } from "@mui/material";
import { WiLabel } from "@wi-themes/minimal-override";
import Iconify from "@wi-themes/minimal/src/components/Iconify";

import i18n from "./i18n";

import WiArchivedLabelProps from "./@types/WiArchivedLabelProps";
import useI18n from "../../hooks/useI18n";


const WiArchivedLabel = (props: WiArchivedLabelProps) => {

    const {
        hide
    } = props;

    const theme = useTheme();
    const { lang } = useI18n();

    return (
        <>
            {!hide &&
                <WiLabel
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={'error'}
                    sx={{
                        textTransform: 'capitalize',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        borderRadius: 0,
                        px: '12px',
                        height: '30px'
                    }}
                >
                    <Iconify sx={{ mr: '8px' }} icon={'eva:archive-outline'} width={16} height={16} />
                    {i18n[lang.language].archived}
                </WiLabel>
            }
        </>
    );
}


export default WiArchivedLabel;