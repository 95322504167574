import React, { useState } from 'react';
import { MenuItem, Divider, Stack, Typography, Box } from '@mui/material';
import { WiLabel, WiTableMoreMenu, WiTag } from '@wi-themes/minimal-override/src/components';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import useI18n from '@wi-core/ui/src/hooks/useI18n';
import { useDateTimeTz } from '@wi-themes/minimal-override';
import { IsVisible, WiDescriptionItem, WiLabelOffset, WiTagManyElements, useWiPermissionContext } from '@wi-core/ui';
import i18n from './i18n';

import WiReservationInfoProps from './@types/WiReservationInfoProps';
import ReservationStatusColor from './config/ReservationStatusColor';
import { ReservationOrigin, ReservationStatus } from '@wi-core/shared-blocks';

const WiReservationInfo = (props: WiReservationInfoProps) => {
    const { row, showMenu = true, onTransfer, onCancel, onSchedule, onHistory } = props;

    const { lang, translate } = useI18n();
    const { fDateTime, getDateWithOffset } = useDateTimeTz();
    const { getCurrentUser } = useWiPermissionContext();

    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const startAtWithOffset = getDateWithOffset(new Date(row.startAt), row.organizationalUnitOffSet);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const getDuration = () => {
        const start = new Date();
        const end = new Date(row.expiresAt);
        const duration = end.getTime() - start.getTime();
        const minutes = Math.floor(duration / 1000 / 60);
        const hours = Math.floor(minutes / 60);

        if (row.status === ReservationStatus.Cancelled) {
            return '-';
        }

        if (hours > 24) {
            const day = Math.floor(hours / 24);
            return `${day} ${day > 1 ? translate('common.unit_measurement.days') : translate('common.unit_measurement.day')}`;
        }

        if (minutes > 60) {
            return `${Math.floor(minutes / 60)} ${translate('common.unit_measurement.hours')}`;
        }

        if (minutes < 0) {
            return '-';
        }

        return `${minutes} ${translate('common.unit_measurement.minutes')}`;

    };

    const isImportReservation = () => {

        if (!row.healthPlanId || row.procedures.length === 0) {
            return true;
        }

        return false;
    }

    return (
        <Stack direction="column" justifyContent="space-between" p={3} py={2} spacing={0.5}>
            <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                <IsVisible when={row.origin === ReservationOrigin.Import}>
                    <WiLabel color={'warning'}>
                        {translate(`common.reservation_origin.${row.origin}`)}
                    </WiLabel>
                </IsVisible>
                <WiLabel color={ReservationStatusColor[row.status] || 'default'}>
                    {translate(`common.reservation_status.${row.status}`)}
                </WiLabel>
            </Stack>
            <Stack direction="row">
                <Stack flex={1} direction="column" spacing={0.5}>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'grid',
                            gridTemplateColumns: {
                                sm: 'repeat(1, 1fr)',
                                md: 'repeat(3, 1fr)'
                            }
                        }}
                    >
                        <Stack direction="column" spacing={0.5}>
                            <Stack direction="row" spacing={0.5}>
                                <WiLabelOffset
                                    variant="body2"
                                    offset={row.organizationalUnitOffSet}
                                    sx={{ color: 'text.secondary' }}
                                >
                                    {translate(`common.time`)}
                                </WiLabelOffset>
                                <Typography variant="body2">
                                    {fDateTime(startAtWithOffset, 'dd/MM/yyyy HH:mm')}
                                </Typography>
                            </Stack>
                            <WiDescriptionItem label={i18n[lang.language].patient}>{row.personName || "-"}</WiDescriptionItem>
                            <WiDescriptionItem label={translate(`common.unit`)}>
                                {row.organizationalUnitName}
                            </WiDescriptionItem>
                            <WiDescriptionItem label={translate(`common.type`)}>
                                {translate(`common.procedure_type.${row.procedures[0]?.type}`)}
                            </WiDescriptionItem>
                        </Stack>

                        <Stack direction="column" spacing={0.5}>
                            <WiDescriptionItem label={translate(`common.professional`)}>
                                {row.professionalName || '-'}
                            </WiDescriptionItem>
                            <WiDescriptionItem label={translate(`common.room`)}>{row.roomName || "-"}</WiDescriptionItem>
                            <WiDescriptionItem label={translate(`common.health_plan`)}>
                                {row.healthPlanName || '-'}
                            </WiDescriptionItem>
                            <WiDescriptionItem label={i18n[lang.language].execution_time}>
                                {row.executionTime > 0
                                    ? `${row.executionTime?.toString()} ${translate(`common.unit_measurement.minutes`)}`
                                    : '-'}
                            </WiDescriptionItem>
                        </Stack>

                        <Stack direction="column" justifyContent="center" spacing={0.5}>
                            <WiDescriptionItem label={i18n[lang.language].expires_in}>
                                {getDuration()}
                            </WiDescriptionItem>
                            <WiDescriptionItem label={i18n[lang.language].reserved_by}>
                                {row.ownerUsername}
                            </WiDescriptionItem>
                            <WiDescriptionItem label={i18n[lang.language].reason}>
                                {row.reasonDescription}
                            </WiDescriptionItem>
                            <Stack direction="row" spacing={0.5}>
                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                    {translate('common.tags')}
                                </Typography>{' '}
                                <WiTagManyElements
                                    variant="body2"
                                    tags={row.tags.map(tag => {
                                        return {
                                            tagColor: tag.color,
                                            tagId: tag.tagId,
                                            tagName: tag.name
                                        };
                                    })}
                                />
                            </Stack>
                        </Stack>
                    </Box>

                    <Stack direction="column" spacing={0.5}>
                        <Box sx={{ lineHeight: 0.5 }}>
                            <Typography variant="body2" component="span" sx={{ color: 'text.secondary', pr: 0.5 }}>
                                {i18n[lang.language].description}:
                            </Typography>
                            <Typography variant="body2" component="span">
                                {row.description}
                            </Typography>
                        </Box>
                        <Box sx={{ lineHeight: 0.5 }}>
                            <Typography variant="body2" component="span" sx={{ color: 'text.secondary', pr: 0.5 }}>
                                {translate(`common.procedures`)}:
                            </Typography>
                            <Typography variant="body2" component="span">
                                {row.procedures.map(procudure => procudure.procedureName).join(' / ')}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Stack direction="column" justifyContent="center" alignItems="center" width={36}>
                    <IsVisible when={showMenu}>
                        <WiTableMoreMenu
                            width={230}
                            open={openMenu}
                            onOpen={handleOpenMenu}
                            onClose={handleCloseMenu}
                            actions={
                                <>
                                    <IsVisible when={row.status == ReservationStatus.Reservation && !isImportReservation()}>
                                        <MenuItem
                                            onClick={() => {
                                                onSchedule?.();
                                                handleCloseMenu();
                                            }}
                                        >
                                            <Iconify icon={'tabler:user-pin'} sx={{ color: 'text.secondary' }} />
                                            {i18n[lang.language].schedule}
                                        </MenuItem>

                                    </IsVisible>
                                    <IsVisible when={row.status == ReservationStatus.Reservation && row.ownerUsername == getCurrentUser()?.username}>
                                        <MenuItem
                                            onClick={() => {
                                                onTransfer?.();
                                                handleCloseMenu();
                                            }}
                                        >
                                            <Iconify icon={'tabler:transfer'} sx={{ color: 'text.secondary' }} />
                                            {i18n[lang.language].transfer}
                                        </MenuItem>
                                    </IsVisible>


                                    <MenuItem
                                        onClick={() => {
                                            onHistory?.();
                                            handleCloseMenu();
                                        }}
                                    >
                                        <Iconify icon={'ic:round-history'} sx={{ color: 'text.secondary' }} />
                                        {i18n[lang.language].history}
                                    </MenuItem>
                                    <IsVisible when={row.status == ReservationStatus.Reservation && row.ownerUsername == getCurrentUser()?.username}>
                                        <>
                                            <Divider />
                                            <MenuItem
                                                onClick={() => {
                                                    onCancel?.();
                                                    handleCloseMenu();
                                                }}
                                                sx={{ color: 'error.main' }}
                                            >
                                                <Iconify icon={'prime:times-circle'} />
                                                {i18n[lang.language].cancel}
                                            </MenuItem>
                                        </>
                                    </IsVisible>
                                </>
                            }
                        />
                    </IsVisible>
                </Stack>
            </Stack>
        </Stack >
    );
};

export default WiReservationInfo;
