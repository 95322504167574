import { useEffect, useState } from "react";
import { useDebounce, useI18n } from "@wi-core/ui";
import { useSnackbar } from "notistack";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";

import { PaginationDefaultValues } from "../../../pagination";
import useReasonQuery from '../use-reason-query/useReasonQuery';
import { SearchReasonRequest } from '../../@types';
import UseReasonToAutocompleteProps from './@types/UseReasonToAutocompleteProps';


const useReasonToAutocomplete = (props: UseReasonToAutocompleteProps) => {

    const { 
        licenseKey, 
        organizationalUnitKey, 
        type, 
        classification, 
        status 
    } = props;

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();
    const { reasonSearchQuery } = useReasonQuery();
    const { debounce } = useDebounce();

    const [reasonsToAutocomplete, setReasonsToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [searchRequest, setSearchRequest] = useState<SearchReasonRequest>({
        keyword: '',
        licensesKey: licenseKey,
        type: type,
        classification: classification,
        organizationalUnitKey: organizationalUnitKey,
        page: PaginationDefaultValues.PAGE,
        size: PaginationDefaultValues.SIZE,
        sort: PaginationDefaultValues.SORT,
        status
    });

    const { 
        data, 
        isLoading, 
        isError, 
        error
    } = reasonSearchQuery(searchRequest);

    useEffect(() => {
        setSearchRequest((current) => ({
            ...current,
            type,
            keyword: ''
        }))
    }, [type])

    useEffect(() => {
        setReasonsToAutocomplete(
            (data?.content || []).map(e => {
                return {
                    id: e.id,
                    label: e.description
                };
            })
        );
    }, [data]);

    useEffect(() => {
        if (isError) {
            setReasonsToAutocomplete([]);
            enqueueSnackbar(translate('common.search_error.error_loading_reason'), { variant: 'error' });
        }
    }, [isError]);


    const handleFilterReasonsByDescription = (value: string) => {
        debounce(() => {
            if(reasonsToAutocomplete.findIndex(e => e.label === value) === -1) {
                setSearchRequest((current) => ({
                    ...current,
                    keyword: value
                }));
            }
        }, 500);
    };

    const changeReasons = (newReasons: WiAutocompleteOption[]) => {
        setReasonsToAutocomplete(newReasons);
    };

    return {
        reasonsToAutocomplete,
        loadingReasons: isLoading,
        changeReasons,
        handleFilterReasonsByDescription
    };
};

export default useReasonToAutocomplete;
