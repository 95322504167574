import { AxiosResponse } from "axios";
import api from "@wi-config/axios";

import { PaginationResponse } from "../../pagination";
import SearchTagRequest from "../@types/search-tag/SearchTagRequest";
import SearchTagResponse from "../@types/search-tag/SearchTagResponse";


const PATH_TAG: string = 'api/v1/planning/tags';

const TagApi = {
    search: (data?: SearchTagRequest): Promise<AxiosResponse<PaginationResponse<SearchTagResponse>>> => {
        return api.get(`${ PATH_TAG }/search`, { params: data });
    }
};

export default TagApi;