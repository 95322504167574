import { ExceptionCodes } from "@wi-core/shared-blocks";


const calendarExceptionCodes: ExceptionCodes = {
    "PL UC046 0001": "calendar_create.invalid_organization_unit",
    "PL UC046 0002": "calendar_create.user_not_authorized",
    "PL UC046 0003": "calendar_create.invalid_name_field",
    "PL UC046 0004": "calendar_create.invalid_color_field",
    "PL UC046 0005": "calendar_create.invalid_%s",
    "PL UC046 0006": "calendar_create.invalid_type_field",
    "PL UC046 0007": "calendar_create.invalid_interval_between_disponibilities_field",

    "PL UC047 0001": "calendar_update.invalid_organization_unit",
    "PL UC047 0002": "calendar_update.user_not_authorized",
    "PL UC047 0003": "calendar_update.invalid_name_field",
    "PL UC047 0004": "calendar_update.invalid_color_field",
    "PL UC047 0005": "calendar_update.invalid_type_field",
    "PL UC047 0007": "calendar_update.invalid_interval_between_disponibilities_field",

    "PL UC072 0001": "calendar_get.calendar_not_found",
    "PL UC072 0002": "calendar_get.user_not_authorized",

    "PL UC048 0001": "calendar_archive.user_not_authorized",
    "PL UC048 0002": "calendar_archive.calendar_not_found",
    "PL UC048 0003": "calendar_archive.invalid_name_field",
    "PL UC048 0004": "calendar_archive.calendar_archived",

    "PL UC049 0001": "calendar_unarchive.user_not_authorized",
    "PL UC049 0002": "calendar_unarchive.calendar_not_found",
    "PL UC049 0003": "calendar_unarchive.invalid_name_field",
    "PL UC049 0004": "calendar_unarchive.calendar_unarchived"
}

export default {
    ...calendarExceptionCodes
};
