import { AxiosResponse } from 'axios';
import api from '@wi-config/axios';

import { CommonCreateUpdateResponse } from '../../common-create-update-response';
import CreateReasonRequest from '../@types/create-reason/CreateReasonRequest';
import SearchReasonRequest from '../@types/search-reason/SearchReasonRequest';
import UpdateReasonRequest from '../@types/update-reason/UpdateReasonRequest';
import GetReasonResponse from '../@types/get-reason/GetReasonResponse';
import { PaginationResponse } from '../../pagination';
import SearchReasonResponse from '../@types/search-reason/SearchReasonResponse';
import { ArchiveUnarchiveResponse } from '../../archive-unarchive';

const PATH_REASON: string = 'api/v1/planning/reason';

const ReasonApi = {
    create: async (request: CreateReasonRequest, signal?: AbortSignal): Promise<CommonCreateUpdateResponse> => {
        try {
            const { data } = await api.post<CommonCreateUpdateResponse>(`${PATH_REASON}`, request, { signal });

            return data;
        }
        catch (error) {
            throw error;
        }
    },
    update: async (id: string, request: UpdateReasonRequest, signal?: AbortSignal): Promise<CommonCreateUpdateResponse> => {
        try {
            const { data } = await api.put<CommonCreateUpdateResponse>(`${PATH_REASON}/${id}`, request, { signal });

            return data;
        }
        catch (error) {
            throw error;
        }
    },
    get: async (id: string, signal?: AbortSignal): Promise<GetReasonResponse> => {
        try {
            const { data } = await api.get<GetReasonResponse>(`${PATH_REASON}/${id}`, { signal });

            return data;
        }
        catch (error) {
            throw error;
        }
    },
    search: async (request: SearchReasonRequest, signal?: AbortSignal): Promise<PaginationResponse<SearchReasonResponse>> => {
        try {
            const { data } = await api.get<PaginationResponse<SearchReasonResponse>>(`${PATH_REASON}/search`, { params: request, signal });

            return data;
        }
        catch (error) {
            throw error;
        }
    },
    archive: async (id: string, code: string, signal?: AbortSignal): Promise<ArchiveUnarchiveResponse> => {
        try {
            const { data } = await api.put<ArchiveUnarchiveResponse>(`${PATH_REASON}/${id}/archive`, { code, signal });

            return data;
        }
        catch (error) {
            throw error;
        }
    },
    unarchive: async (id: string, code: string, signal?: AbortSignal): Promise<ArchiveUnarchiveResponse> => {
        try {
            const { data } = await api.put(`${PATH_REASON}/${id}/unarchive`, { code, signal });

            return data;
        }
        catch (error) {
            throw error;
        }
    }
};

export default ReasonApi;
