import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Button } from '@mui/material';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import { useI18n } from '@wi-core/ui';

import i18n from '../../i18n';


export default function WiSettingFullscreen() {

    const { lang } = useI18n();
    const [fullscreen, setFullscreen] = useState(false);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        }
    };

    return (
        <Button
            fullWidth
            size="large"
            variant="outlined"
            color={fullscreen ? 'primary' : 'inherit'}
            startIcon={<Iconify icon={fullscreen ? 'ic:round-fullscreen-exit' : 'ic:round-fullscreen'} />}
            onClick={toggleFullScreen}
            sx={{
                fontSize: 14,
                ...(fullscreen && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                }),
            }}
        >
            {fullscreen ? i18n[lang.language].exitFullscreen : i18n[lang.language].fullscreen}
        </Button>
    );
}