
import { useEffect } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { SearchLocalizationByZipCodeResponse } from '@wi-core/shared-blocks';

import useI18n from '../../../hooks/useI18n';


const useWiAddressForm = () => {

    const { lang } = useI18n();
    const { setValue, trigger } = useFormContext();
    const { isSubmitted } = useFormState();

    useEffect(() => {
        triggerForm();
    }, [lang.language]);

    
    const setFormValues = (data: SearchLocalizationByZipCodeResponse) => {
        setFieldsValues(data);
        triggerForm();
    }

    const setFieldsValues = (data: SearchLocalizationByZipCodeResponse) => {
        setValue('address.publicPlace', data.address);
        setValue('address.district', data.district);
        setValue('address.city', { id: data.city.id, label: data.city.name });
        setValue('address.state', { id: data.city.state.id, label: data.city.state.name });
        setValue('address.country', { id: data.city.state.country.id, label: data.city.state.country.name });
    }

    const cleanFields = () => {
        setValue('address.publicPlace', '');
        setValue('address.district', '');
        setValue('address.city', null);
        setValue('address.state', null);
        setValue('address.country', null);
    }


    const triggerForm = () => {
        if(!isSubmitted) return;

        trigger([
            'address.zipCode', 
            'address.number', 
            'address.publicPlace', 
            'address.district', 
            'address.city', 
            'address.state', 
            'address.country'
        ]);
    }

    
    return {
        setFormValues,
        cleanFields,
        triggerForm
    }
}

export default useWiAddressForm;