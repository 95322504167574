import { ExceptionCodes } from "@wi-core/shared-blocks";


const healthCardExceptionCodes: ExceptionCodes = {
    //update_health_card
    "PE UC011 0001": "update_health_card.user_not_authorized_update",
    "PE UC011 0002": "update_health_card.invalid_health_plan",
    "PE UC011 0003": "update_health_card.person_not_found",

    //get_health_card
    "PE UC012 0001": "get_health_card.person_not_found",
    "PE UC012 0002": "get_health_card.user_not_authorized_view"
}

export default {
    ...healthCardExceptionCodes
};
