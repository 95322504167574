import { ExceptionCodes } from "@wi-core/shared-blocks";


const appointmentExceptionCodes: ExceptionCodes = {
    //search_on_demand_appointment_history
    "SC UC139 0001":"search_on_demand_appointment_history.license_authorization_error",
    "SC UC139 0002":"search_on_demand_appointment_history.organizational_unit_authorization_error",
    "SC UC139 0003":"search_on_demand_appointment_history.error_person_integration_link_not_found",

    //get_appointment_procedure_history
    "SC UC140 0001": "get_appointment_procedure_history.organizational_unit_authorization_error",

    //check_inconsistencies_for_check_in
    "SC UC141 0001": "check_inconsistencies_for_check_in.appointment_not_found",
    "SC UC141 0002": "check_inconsistencies_for_check_in.user_not_organization_unit_authorized",

    //perform_check_in
    "SC UC054 0001": "perform_check_in.user_not_organization_unit_authorized",
    "SC UC054 0002": "perform_check_in.appointment_not_found",
    "SC UC054 0003": "perform_check_in.appointment_is_already_in_status_awaiting_service",
    "SC UC054 0004": "perform_check_in.appointment_cannot_be_changed_to_awaiting_service_status",
    "SC UC054 0005": "perform_check_in.appointment_has_pending_inconsistencies",
    "SC UC054 0006": "perform_check_in.invalid_inconsistencies",
    "SC UC054 0007": "perform_check_in.invalid_justification",
    "SC UC054 0008": "perform_check_in.error_when_opening_attendance"
}

export default {
    ...appointmentExceptionCodes
};
