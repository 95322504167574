import React from 'react';
import { Link, ListItemText, Popover, Stack, Typography, List, ListItem } from '@mui/material';

import WiTagManyElementsProps from './@types/WiTagManyElementsProps';
import { WiTag } from '@wi-themes/minimal-override';

const WiTagManyElements = ({ sx, tags, variant }: WiTagManyElementsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!tags || tags.length === 0) {
        return (
            <Typography variant={variant} sx={{ ...sx }}>
                -
            </Typography>
        );
    }

    const renderTags = () => {
        const tagsToRender = tags.slice(0, 3);

        return tagsToRender.map((tag, index) => (
            <WiTag key={tag.tagId} color={tag.tagColor} label={tag.tagName} />
        ));

    }

    return (
        <>
            <Stack direction="row" spacing={0.5}>
                {renderTags()}
                {tags.length > 3 && (
                    <Link
                        variant={variant}
                        onClick={handleClick}
                        sx={{
                            cursor: 'pointer'
                        }}
                    >
                        +{tags.length - 3}
                    </Link>
                )}
            </Stack>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                PaperProps={{
                    sx: {
                        minWidth: 174
                    }
                }}
            >
                <List sx={{pt: 0.5}}>
                    {tags.map((tag, index) => (
                        <ListItem key={index} sx={{p: 1, pb: 0, pt: 0.5}}>
                            <WiTag key={tag.tagId} color={tag.tagColor} label={tag.tagName} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    );
};

export default WiTagManyElements;
