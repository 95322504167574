import WiDialogSelectPersonDataType from "../@types/WiDialogSelectPersonDataType";
import WiDialogSelectPersonFormMode from "../@types/WiDialogSelectPersonFormMode";
import WiDialogSelectPersonViewMode from "../@types/WiDialogSelectPersonViewMode";

const InitialValues: WiDialogSelectPersonDataType = {
    viewMode: WiDialogSelectPersonViewMode.List,
    fullScreen: false,
    selectedPerson: null,
    personIdToEdit: null,
    formMode: WiDialogSelectPersonFormMode.New
}

export default InitialValues;