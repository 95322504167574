import api from '@wi-config/axios';
import { AxiosResponse } from 'axios';
import {
    PaginationResponse,
    ArchiveUnarchiveResponse,
    CommonCreateUpdateResponse
} from '@wi-core/shared-blocks';
import { CreateSpecialRuleRequest, GetSpecialRule, ListSpecialRulesByIdResponse, SearchSpecialRuleRequest, SearchSpecialRuleResponse, UpdateSpecialRuleRequest } from '../@types';

const getPathSpecialRule = (licenseKey: string) => `api/v1/planning/${licenseKey}/special-rules`;

const SpecialRuleApi = {
    get: async (id: string, licenseKey: string): Promise<GetSpecialRule> => {
        try {
            const { data } = await api.get(`${getPathSpecialRule(licenseKey)}/${id}`);

            return data;
        }
        catch (error) {
            throw error;
        }
    },
    byIds: async (ids: string): Promise<ListSpecialRulesByIdResponse[]> => {
        try {
            const { data } = await api.get(`api/v1/planning/special-rules/by-ids`, { params: { ids } });

            return data;
        }
        catch (error) {
            throw error;
        }
    },
};

export default SpecialRuleApi;
