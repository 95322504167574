import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { WiAutocomplete, WiTextField, WiTitle } from "@wi-themes/minimal-override";
import useI18n from "../../hooks/useI18n";

import i18n from "./i18n";
import useSearch from "./hooks/useSearch";

import WiAddressProps from "./@types/WiAddressProps";
import { useFormContext } from 'react-hook-form';


const WiAddress = (props: WiAddressProps) => {

    const {
        title,
        hideTitle,
        required
    } = props;

    const { lang } = useI18n();

    const {
        countries,
        states,
        cities,

        loadingCountries,
        loadingStates,
        loadingCities,
        laodingLocalization,

        onInputChangeCountry,
        onInputChangeState,
        onInputChangeCity
    } = useSearch();

    const { watch } = useFormContext();
    const country = watch('address.country');
    const state = watch('address.state');

    return (
        <Box
            sx={{
                display: 'grid',
                rowGap: 2
            }}
        >
            {!hideTitle && <WiTitle>{title || `${i18n[lang.language].address}:`}</WiTitle>}

            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <WiTextField
                    name="address.zipCode"
                    label={i18n[lang.language].zipCode}
                    size="small"
                    mask={Number}
                    InputProps={{
                        endAdornment: (
                            <React.Fragment>
                                {laodingLocalization && <CircularProgress color="inherit" size={20} />}
                            </React.Fragment>
                        ),
                    }}/>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
            >
                <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                    <WiTextField name="address.publicPlace" label={i18n[lang.language].publicPlace} size="small" required={required} />
                </Box>
                <WiTextField name="address.number" label={i18n[lang.language].number} size="small" mask={Number} />
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <WiTextField name="address.district" label={i18n[lang.language].district} size="small" />

                <WiTextField name="address.complement" label={i18n[lang.language].complement} size="small" />
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
            >
                <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                    <WiAutocomplete
                        name="address.country"
                        label={i18n[lang.language].country}
                        options={countries}
                        loading={loadingCountries || laodingLocalization}
                        onInputChange={onInputChangeCountry}
                        required={required}
                    />
                </Box>

                <WiAutocomplete
                    name="address.state"
                    label={i18n[lang.language].state}
                    options={states}
                    loading={loadingStates || laodingLocalization}
                    onInputChange={onInputChangeState}
                    required={country?.id && states.length > 0 && required}
                />
            </Box>

            <WiAutocomplete
                name="address.city"
                label={i18n[lang.language].city}
                options={cities}
                loading={loadingCities || laodingLocalization}
                onInputChange={onInputChangeCity}
                required={state?.id && cities.length > 0 && required}
            />
        </Box>
    );
}

export default WiAddress;
