import { AppointmentDisponibilityType, DisponibilityFilter, DisponibilitySelected, ProcedureType, SearchDisponibilityType } from "@wi-core/shared-blocks";
import DialogSelectDisponibilityContextType from "../@types/DialogSelectDisponibilityContextType";


const InitialValues: DialogSelectDisponibilityContextType = {
    data: {
        fullScreen: false,
        currentDate: '',
        noSearch: false,
        searchType: SearchDisponibilityType.NextAvailable,
        disponibilitySelected: null,
        filter: {
            licenseKey: '',
            executionTime: '',
            preparationTime: '',
            reposeTime: '',
            professional: null,
            requireProfessional: false,
            intervalBetweenDisponibilities: '',
            appointmentId: undefined,
            organizationalUnit: null,
            healthPlansId: [],
            procedureType: ProcedureType.Exam,
            items: [],
            appointmentDisponibilityType: AppointmentDisponibilityType.Normal
        },
        procedureIdByHealthPlanId: {}
    },
    initialize: (
        currentDate: string, 
        newFilter: DisponibilityFilter, 
        newDisponibilitySelected: DisponibilitySelected | null,
        procedureIdByHealthPlanId: { [healthPlanId: string]: string[] }
    ) => { },
    changeFullScreen: () => { },
    changeDisponibilitySelected: (newDisponibilitySelected: DisponibilitySelected | null) => { },
    changeFilter: (newFilter: DisponibilityFilter) => { },
    changeCurrentDate: (newCurrentDate: string, searchType: SearchDisponibilityType, noSearch: boolean) => { }
}

export default InitialValues;
