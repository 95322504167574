import { useEffect, useState } from "react";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";
import { useDebounce, useI18n } from "@wi-core/ui";
import { useSnackbar } from "notistack";

import { PaginationDefaultValues } from "../../pagination";
import RoomApi from "../api/RoomApi";
import SearchRoomRequest from "../@types/search-room/SearchRoomRequest";
import SearchRoomResponse from "../@types/search-room/SearchRoomResponse";
import { StatusFilter } from "../../status";


const useRoom = (organizationalUnitRequired: boolean, organizationalUnitKey?: string) => {

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();
    const { debounce } = useDebounce();

    const [room, setRoom] = useState<SearchRoomResponse[]>([]);
    const [roomToAutocomplete, setRoomToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [loadingRoom, setLoadingRoom] = useState<boolean>(false);
    const [filterByName, setFilterByName] = useState<string>('');
    const [filterByOrganizationalUnitKey, setFilterByOrganizationalUnitKey] = useState<string | undefined>();


    useEffect(() => {
        searchRoom();
    }, []);

    const searchRoom = (search?: SearchRoomRequest) => {

        if (organizationalUnitRequired && (organizationalUnitKey === null || filterByOrganizationalUnitKey === null)) return;

        setLoadingRoom(true);

        search = search || {
            keyword: filterByName,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            organizationalUnitsKey: organizationalUnitKey || filterByOrganizationalUnitKey,
            status: StatusFilter.Ok
        }

        RoomApi.search(search)
            .then((result) => {

                const { data: { content } } = result;

                setRoom(content);
                setRoomToAutocomplete(content.map(e => {
                    return {
                        id: e.id,
                        label: e.name
                    }
                }));
            })
            .catch((error) => {
                setRoom([]);
                setRoomToAutocomplete([]);

                enqueueSnackbar(
                    translate('common.search_error.error_loading_rooms'),
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setLoadingRoom(false);
            })
    }

    const handleFilterRoomByName = (value: string) => {
        setFilterByName(value);

        const search: SearchRoomRequest = {
            keyword: value,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            organizationalUnitsKey: organizationalUnitKey || filterByOrganizationalUnitKey,
            status: StatusFilter.Ok
        };

        searchRoom(search);
    }

    const handleFilterroomByOrganizationalUnitKey = (newOrganizationalUnitKey?: string) => {

        setFilterByOrganizationalUnitKey(newOrganizationalUnitKey);

        const search: SearchRoomRequest = {
            keyword: filterByName,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            organizationalUnitsKey: newOrganizationalUnitKey,
            status: StatusFilter.Ok
        };

        searchRoom(search);
    }

    return {
        room,
        roomToAutocomplete,
        loadingRoom,
        handleFilterRoomByName,
        handleFilterroomByOrganizationalUnitKey
    }
}

export default useRoom;
