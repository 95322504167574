import { useState } from "react";
import { IconButtonAnimate } from "@wi-themes/minimal/src/components/animate";
import Iconify from "@wi-themes/minimal/src/components/Iconify";
import { Box, CardActionArea, Stack, Typography } from "@mui/material";
import { APPS } from "@wi-core/shared-blocks";
import Scrollbar from "@wi-themes/minimal/src/components/Scrollbar";
import { useI18n } from "@wi-core/ui";

import WiAppLogo from "../wi-app-logo/WiAppLogo";

import WiQuickAccessMenuProps from "./@types/WiQuickAccessMenuProps";
import WiMenuPopover from "../wi-menu-popover/WiMenuPopover";



const WiQuickAccessMenu = (props: WiQuickAccessMenuProps) => {

    const { translate } = useI18n();

    const [open, setOpen] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButtonAnimate 
                sx={{
                    ...(open && { bgcolor: 'action.selected' })
                }}
                onClick={handleOpen}
            >
                <Iconify icon="eva:grid-outline" />
            </IconButtonAnimate>


            <WiMenuPopover
                onClose={handleClose}
                arrowPosition="top-left"
                anchorEl={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Scrollbar
                    sx={{
                        width: 288,
                        maxHeight: 300,
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            columnGap: 1,
                            rowGap: 1,
                            gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'repeat(3, 1fr)' }
                        }}
                    >
                        {APPS.map(app => (
                            <CardActionArea
                                key={app.key}
                                sx={{
                                    maxHeight: '82px',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    ':active': {
                                        borderRadius: '8px'
                                    },
                                    ':hover': {
                                        maxHeight: 'none',
                                        '#app-name': {
                                            display:'block',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'normal',
                                        }
                                    }
                                }}
                            >
                                <Stack
                                    direction="column"
                                    width={80}
                                    spacing={1}
                                    onClick={() => window.location.pathname = app.path}
                                    sx={{
                                        alignItems: 'center',
                                        p: '8px',
                                        cursor: 'pointer',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    <WiAppLogo
                                        icon={app.icon}
                                        color={app.color}
                                    />

                                    <Typography 
                                        id="app-name"
                                        variant="caption" 
                                        textAlign="center"
                                        width="100%"
                                        sx={{
                                            display:'inline-block',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {translate(app.name)}
                                    </Typography>
                                </Stack>
                            </CardActionArea>
                        ))}
                    </Box>
                </Scrollbar>
            </WiMenuPopover>
        </>
    );
}

export default WiQuickAccessMenu;