import { useEffect } from 'react';
import { AnimatePresence, m } from 'framer-motion';
import { alpha, styled } from '@mui/material/styles';
import { Divider, Typography, Stack } from '@mui/material';
import cssStyles from '@wi-themes/minimal/src/utils/cssStyles';
import { IconButtonAnimate, varFade } from '@wi-themes/minimal/src/components/animate';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import Scrollbar from '@wi-themes/minimal/src/components/Scrollbar';
import { useI18n } from '@wi-core/ui';

import useSettings from '../../hooks/use-settings/useSettings';

import WiSettingMode from './components/wi-setting-mode/WiSettingMode';
import WiSettingStretch from './components/wi-setting-stretch/WiSettingStretch';
import WiSettingFullscreen from './components/wi-setting-full-screen/WiSettingFullscreen';

import i18n from './i18n';
import { NAVBAR } from '../../config';


// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
	...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
	top: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	position: 'fixed',
	overflow: 'hidden',
	width: NAVBAR.BASE_WIDTH,
	flexDirection: 'column',
	margin: theme.spacing(2),
	paddingBottom: theme.spacing(3),
	zIndex: theme.zIndex.drawer + 3,
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	boxShadow: `-24px 12px 32px -4px ${alpha(
		theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
		0.16
	)}`,
}));

// ----------------------------------------------------------------------

export default function WiSettings() {

	const { lang } = useI18n();

	const {
		open,
		onToggleSetting,
		onResetSetting,
	} = useSettings();

	const varSidebar = varFade({
		distance: NAVBAR.BASE_WIDTH,
		durationIn: 0.32,
		durationOut: 0.32,
	}).inRight;

	useEffect(() => {
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [open]);

	return (
		<AnimatePresence>
			{open && <>
				<RootStyle {...varSidebar}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{ py: 2, pr: 1, pl: 2.5 }}
					>
						<Typography variant="subtitle1">{i18n[lang.language].settings}</Typography>
						<div>
							<IconButtonAnimate onClick={onResetSetting}>
								<Iconify icon={'ic:round-refresh'} width={20} height={20} />
							</IconButtonAnimate>
							<IconButtonAnimate onClick={onToggleSetting}>
								<Iconify icon={'eva:close-fill'} width={20} height={20} />
							</IconButtonAnimate>
						</div>
					</Stack>

					<Divider sx={{ borderStyle: 'dashed' }} />

					<Scrollbar sx={{ flexGrow: 1 }}>
						<Stack spacing={3} sx={{ p: 3 }}>
							<Stack spacing={1.5}>
								<Typography variant="subtitle2">{i18n[lang.language].mode}</Typography>
								<WiSettingMode />
							</Stack>

							<Stack spacing={1.5}>
								<Typography variant="subtitle2">{i18n[lang.language].stretch}</Typography>
								<WiSettingStretch />
							</Stack>

							<WiSettingFullscreen />
						</Stack>
					</Scrollbar>
				</RootStyle>
			</>}
		</AnimatePresence>
	);
}
