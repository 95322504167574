import { AxiosResponse } from 'axios';
import api from '@wi-config/axios';
import { PaginationResponse } from '@wi-core/shared-blocks';

import SearchProfessionalRequest from '../@types/search-professional/SearchProfessionalRequest';
import SearchProfessionalResponse from '../@types/search-professional/SearchProfessionalResponse';
import SearchProfessionalReportRequest from '../@types/search-professional-report/SearchProfessionalReportRequest';
import SearchProfessionalReportResponse from '../@types/search-professional-report/SearchProfessionalReportResponse';
import SearchProfessionalBySpecialityRequest from '../@types/search-professional-by-speciality/SearchProfessionalBySpecialityRequest';
import SearchProfessionalBySpecialityResponse from '../@types/search-professional-by-speciality/SearchProfessionalBySpecialityResponse';
import CreateProfessionalFromAppointmentRequest from '../@types/create-professional-from-appointment/CreateProfessionalFromAppointmentRequest';
import CreateProfessionalFromAppointmentResponse from '../@types/create-professional-from-appointment/CreateProfessionalFromAppointmentResponse';


const PATH_PROFESSIONAL: string = 'api/v1/planning/professionals';

const ProfessionalApi = {
    search: (data?: SearchProfessionalRequest): Promise<AxiosResponse<PaginationResponse<SearchProfessionalResponse>>> => {
        return api.get(`${PATH_PROFESSIONAL}/search`, { params: data });
    },
    listReport: (data: SearchProfessionalReportRequest): Promise<AxiosResponse<{items: SearchProfessionalReportResponse[]}>> => {
        return api.get(`${PATH_PROFESSIONAL}/report/list`, { params: data });
    },
    searchBySpecialities: (data?: SearchProfessionalBySpecialityRequest): Promise<AxiosResponse<PaginationResponse<SearchProfessionalBySpecialityResponse>>> => {
        return api.get(`${PATH_PROFESSIONAL}/search/by-speciality`, { params: data });
    },
    fromAppointment: (data: CreateProfessionalFromAppointmentRequest): Promise<AxiosResponse<CreateProfessionalFromAppointmentResponse>> => {
        return api.post(`${PATH_PROFESSIONAL}/from-appointment`, data);
    }
};

export default ProfessionalApi;
