import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useI18n } from '@wi-core/ui';
import { WiAutocompleteOption } from '@wi-themes/minimal-override';

import LicenseApi from "../api/LicenseApi";

import LicenseByRole from '../@types/license-by-role/LicenseByRole';


const useSearchLicenseByRoles = (roles: string[]) => {

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [licenses, setLicenses] = useState<LicenseByRole[]>([]);
    const [licensesToAutocomplete, setLicensesToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [loadingLicenses, setLoadingLicenses] = useState<boolean>(false);


    useEffect(() => {
        searchLicenses();
    }, []);

    const searchLicenses = () => {

        setLoadingLicenses(true);

        const search = {
            roles: roles.join(',')
        };

        LicenseApi.listByRoles(search)
            .then(result => {
                const {
                    data: { licenses }
                } = result;

                setLicenses(licenses);
                setLicensesToAutocomplete(
                    licenses.map(e => {
                        return {
                            id: e.key,
                            label: e.name
                        }
                    })
                );
            })
            .catch(error => {
                setLicenses([]);

                enqueueSnackbar(
                    translate('common.search_error.error_loading_licenses'),
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setLoadingLicenses(false);
            });
    };


    return {
        licenses,
        licensesToAutocomplete,
        loadingLicenses
    };
};


export default useSearchLicenseByRoles;