import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useI18n } from "@wi-core/ui";
import { WiSelectTagsOption } from "@wi-themes/minimal-override";

import { PaginationDefaultValues } from "../../pagination";
import { StatusFilter } from "../../status";
import TagApi from "../api/TagApi";
import SearchTagRequest from "../@types/search-tag/SearchTagRequest";
import { TagClassification } from '../@types';


const PAGE_SIZE = 30;


const useTag = (
    licenseRequired: boolean,
    licenseKey?: string,
    status?: StatusFilter,
    classifications?: TagClassification | TagClassification[]
) => {

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [tags, setTags] = useState<WiSelectTagsOption[]>([]);
    const [loadingTags, setLoadingTags] = useState<boolean>(false);
    const [totalTags, setTotalTags] = useState<number>(0);
    const [filterTagsByName, setFilterTagsByName] = useState<string>('');


    useEffect(() => {
        searchTags();
    }, [licenseKey]);


    const searchTags = (search?: SearchTagRequest) => {

        if (licenseRequired && licenseKey == null) return;

        setLoadingTags(true);

        search = search || {
            keyword: filterTagsByName,
            page: PaginationDefaultValues.PAGE,
            size: PAGE_SIZE,
            sort: PaginationDefaultValues.SORT,
            licensesKey: licenseKey,
            status: status,
            classifications: Array.isArray(classifications) ? classifications?.join(',') : classifications
        }

        TagApi.search(search)
            .then((result) => {
                const { data: { content, totalElements } } = result;

                setTags(content.map(e => {
                    return {
                        id: e.id,
                        name: e.name,
                        color: e.color,
                        data: e
                    }
                }));

                setTotalTags(totalElements);
            })
            .catch((error) => {
                setTags([]);

                enqueueSnackbar(
                    translate('common.search_error.error_loading_tags'),
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setLoadingTags(false);
            })
    }

    const handleFilterTagsByName = (value: string) => {
        setFilterTagsByName(value);

        const search: SearchTagRequest = {
            keyword: value,
            page: PaginationDefaultValues.PAGE,
            size: PAGE_SIZE,
            sort: PaginationDefaultValues.SORT,
            licensesKey: licenseKey,
            status: status,
            classifications: Array.isArray(classifications) ? classifications?.join(',') : classifications
        };

        searchTags(search);
    }


    return {
        tags,
        totalTags,
        loadingTags,
        searchTags,
        handleFilterTagsByName
    }
}

export default useTag;
