import { Box, List, ListSubheader, styled } from "@mui/material";

import { useI18n } from "@wi-core/ui";

import { NavSectionProps } from "@wi-themes/minimal/src/components/nav-section/type";

import { NavListRoot } from "../nav-list/NavList";


// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
    <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

export default function NavSection({
    navConfig,
    isCollapse = false,
    ...other
}: NavSectionProps) {

    const {translate} = useI18n();

    return (
        <Box {...other}>
            {navConfig.map((group) => (
                <List key={group.subheader} disablePadding sx={{ px: 2 }}>
                    <ListSubheaderStyle
                        sx={{
                            ...(isCollapse && {
                                opacity: 0,
                            }),
                        }}
                    >
                        {translate(group.subheader)}
                    </ListSubheaderStyle>

                    {group.items.map((list) => (
                        <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                    ))}
                </List>
            ))}
        </Box>
    );
}
