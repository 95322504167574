import en from "./resources/en.json";
import pt_br from "./resources/pt_br.json";


const disponibilityRulesI18n = {
    en: {
        ...en
    },
    pt_br: {
        ...pt_br
    },
};

export default disponibilityRulesI18n;
