import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import WiDescriptionItemProps from "./@types/WiDescriptionItemProps";


const WiDescriptionItem = (props: WiDescriptionItemProps) => {

    const {
        label,
        variant,
        lineBreak,
        children
    } = props;

    if (lineBreak) {
        return (
            <Stack direction="row" spacing={0.5}>
                <Box sx={{ lineHeight: 0.5 }}>
                    <Typography variant={variant || 'body2'} component="span" sx={{ color: 'text.secondary', pr: 0.5 }}>
                        {label}:
                    </Typography>
                    {typeof children === 'string' && <Typography variant={variant || 'body2'} component="span">
                        {children || '-'}
                    </Typography>}
                    {typeof children !== 'string' && children}
                </Box>
            </Stack>
        )
    }

    return (
        <Stack direction="row" spacing={0.5}>
            <Typography variant={variant || 'body2'} component="span" sx={{ color: 'text.secondary' }}>
                {label}:
            </Typography>
            <Stack direction="row" flex={1} sx={{ position: 'relative' }}>
                <Stack direction="row" spacing={0.5} alignItems="center" sx={{ width: '100%', position: 'absolute' }}>
                    {typeof children === 'string' && <Typography variant={variant || 'body2'} noWrap>
                        {children || '-'}
                    </Typography>}
                    {typeof children !== 'string' && children}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default WiDescriptionItem;
