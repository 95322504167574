import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CancelReservationRequest as CancelReservationModel } from "../@types";
import { CommonCreateUpdateResponse } from "../../common-create-update-response";
import ReservationApi from "../api/ReservationApi";
import { ErrorDetail } from '../../error';

export interface CancelReservationRequest {
    organizationalUnitKey: string,
    id: string,
    data: CancelReservationModel
}

export const useCancelReservation = () => {

    const queryClient = useQueryClient();

    return useMutation<CommonCreateUpdateResponse, ErrorDetail, CancelReservationRequest, unknown>(async (request) => {

        var response = await ReservationApi.cancel(request.id, request.organizationalUnitKey, request.data)

        return response.data;
    },
        {
            onSuccess: async (result, variables, context) => {
                await queryClient.invalidateQueries(['useReservationByFilterQuery'])
            },
            onError: (error, variables, context) => {
                throw error;
            }
        }
    );
}
