import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDebounce, useI18n } from "@wi-core/ui";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";

import { PaginationDefaultValues } from "../../pagination";
import UserApi from "../api/UserApi";
import SearchUserResponse from "../@types/search-user/SearchUserResponse";
import SearchUserRequest from "../@types/search-user/SearchUserRequest";


const useUser = () => {

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();
    const { debounce } = useDebounce();

    const [users, setUsers] = useState<SearchUserResponse[]>([]);
    const [usersToAutocomplete, setUsersToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [filterByName, setFilterByName] = useState('');

    useEffect(() => {
        searchUsers();
    }, []);

    const searchUsers = (search?: SearchUserRequest) => {
        
        setLoadingUsers(true);

        search = search || {
            keyword: filterByName,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT
        }

        UserApi.search(search)
            .then(result => {

                const { data: { content } } = result;

                setUsers(content);
                setUsersToAutocomplete(
                    content.map(e => {
                        return {
                            id: e.id,
                            label: `${e.firstName} ${e.lastName}`,
                            data: e
                        }
                    })
                );
            })
            .catch(() => {
                setUsers([]);
                setUsersToAutocomplete([]);

                enqueueSnackbar(
                    translate('common.search_error.error_loading_users'),
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setLoadingUsers(false);
            });
    };

    const handleFilterUserByName = (value: string) => {
        setFilterByName(value);

        searchUsers({
            keyword: value,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT
        });
    }

    return {
        users,
        usersToAutocomplete,
        loadingUsers,
        handleFilterUserByName
    };
}

export default useUser;
