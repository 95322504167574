import { ExceptionCodes } from "@wi-core/shared-blocks";


const draftExceptionCodes: ExceptionCodes = {

    //create_draft
    "SC UC150 0001": "create_draft.organizational_unit_key_error",
    "SC UC150 0002": "create_draft.disponibilities_not_found",
    "SC UC150 0003": "create_draft.invalid_execution_time_error",
    "SC UC150 0004": "create_draft.invalid_preparation_time_error",
    "SC UC150 0005": "create_draft.invalid_repose_time_error",
    "SC UC150 0006": "create_draft.invalid_safe_interval_time_error",

    // "SC UC151 0001": "update_draft.draft_not_found",
    "SC UC151 0002": "update_draft.owner_mismatch",
    "SC UC151 0003": "update_draft.organizational_unit_key_error",
    "SC UC151 0004": "update_draft.invalid_execution_time_error",
    "SC UC151 0005": "update_draft.invalid_preparation_time_error",
    "SC UC151 0006": "update_draft.invalid_repose_time_error",
    "SC UC151 0007": "update_draft.invalid_safe_interval_time_error",

    //find_available_disponibilities
    "PL UC154 0001": "find_available_disponibilities.insuficient_time",
    "PL UC154 0002": "find_available_disponibilities.invalid_procedures",
    "PL UC154 0003": "find_available_disponibilities.invalid_date_error",


    //discard_draft
    // "SC UC153 0001": "discard_draft.draft_not_found",
    "SC UC153 0002": "discard_draft.owner_mismatch",

    //get_draft
    // "SC UC154 0001": "get_draft.draft_not_found",

  
}

export default {
    ...draftExceptionCodes
};
