import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, Divider } from '@mui/material';
import useResponsive from '@wi-themes/minimal/src/hooks/useResponsive';
import useCollapseDrawer from '@wi-themes/minimal/src/hooks/useCollapseDrawer';
import cssStyles from '@wi-themes/minimal/src/utils/cssStyles';
import { NAVBAR } from '@wi-themes/minimal/src/config';
import Scrollbar from '@wi-themes/minimal/src/components/Scrollbar';
import CollapseButton from '@wi-themes/minimal/src/layouts/dashboard/navbar/CollapseButton';
import { useI18n } from '@wi-core/ui';
import Env from '@wi-config/env';

//
import { WiLogo } from '../../../components';
import NavbarProps from './types/NavbarProps';
import AppBanner from './components/app-banner/AppBanner';
import NavSection from './components/nav-section/NavSection';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

// ----------------------------------------------------------------------



const Navbar: React.FC<NavbarProps> = (props) => {

    const {
        appBanner,
        config,
        isOpenSidebar,
        onCloseSidebar
    } = props;

    const theme = useTheme();
    const isDesktop = useResponsive('up', 'lg');

    const { pathname } = useLocation();
    const { translate } = useI18n();

    const {
        isCollapse,
        collapseClick,
        collapseHover,
        onToggleCollapse,
        onHoverEnter,
        onHoverLeave
    } = useCollapseDrawer();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>

            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                    ...(isCollapse && { alignItems: 'center' }),
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <WiLogo disabledLink={true}/>

                    {isDesktop && !isCollapse && (
                        <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
                    )}
                </Stack>

                <AppBanner 
                    isCollapse={isCollapse} 
                    appName={appBanner.appName} 
                    licenseName={appBanner.licenseName} 
                    icon={appBanner.icon}  
                />

            </Stack>

            <Scrollbar 
                sx={{  
                    flex: 1, 
                    '& .simplebar-content': { 
                        flex: 1, 
                        display: 'flex', 
                        flexDirection: 'column' 
                    }, 
                    '& .simplebar-content>div': { 
                        overflowX: 'hidden' 
                    } 
                }}> 

                <NavSection 
                    navConfig={config} 
                    isCollapse={isCollapse} 
                    sx={{
                        marginBottom: '16px',
                        '& li': {
                            width: NAVBAR.DASHBOARD_WIDTH - 32
                        }
                    }} 
                />
            </Scrollbar>
            
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack
                py={1}
                px={3}
                direction="row"
                justifyContent="center"
                color="text.secondary"
            >
                <Typography variant="caption" noWrap>
                    {`${translate('common.version')} ${Env.version}`}
                </Typography>
            </Stack>
        </Box>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
                },
                ...(collapseClick && {
                    position: 'absolute',
                }),
            }}
        >
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: 'dashed',
                            bgcolor: 'background.default',
                            transition: (theme) =>
                                theme.transitions.create('width', {
                                    duration: theme.transitions.duration.standard,
                                }),
                            ...(isCollapse && {
                                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                ...cssStyles(theme).bgBlur(),
                                boxShadow: (theme) => theme.customShadows.z24,
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}

export default Navbar;
