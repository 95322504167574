import api from '@wi-config/axios';
import { CreateDraftFromAppointmentRequest, CreateUpdateDraftRequest, CreateUpdateDraftResponse, DraftOperationType, GetDraftResponse, SearchDraftResponse } from '../@types';
import { ArchiveUnarchiveResponse } from '../../archive-unarchive';
import { ErrorDetail } from '../../error';
import { draftExceptionCodes } from '../i18n';

const PATH_DRAFT: string = 'api/v1/schedule';

const DraftApi = {
    create: async (request: CreateUpdateDraftRequest, signal?: AbortSignal): Promise<CreateUpdateDraftResponse> => {
        try {
            const { data } = await api.post<CreateUpdateDraftResponse>(`${PATH_DRAFT}/draft`, request, { signal });

            for (let i = 0; i < data.appointments.length; i++) {
                if (data.appointments[i].errorCode != null && (draftExceptionCodes[data.appointments[i].errorCode as string] != null)) {
                    throw {
                        item: data,
                        code: data.appointments[i].errorCode,
                        message: data.appointments[i].errorMessage,
                    } as ErrorDetail;
                }
            }

            return data;
        }
        catch (error: any) {
            throw error;
        }
    },
    update: async (id: string, request: CreateUpdateDraftRequest, signal?: AbortSignal): Promise<CreateUpdateDraftResponse> => {
        try {
            const { data } = await api.put<CreateUpdateDraftResponse>(`${PATH_DRAFT}/draft/${id}`, request, { signal });

            for (let i = 0; i < data.appointments.length; i++) {
                if (data.appointments[i].errorCode != null && (draftExceptionCodes[data.appointments[i].errorCode as string] != null)) {
                    throw {
                        item: data,
                        code: data.appointments[i].errorCode,
                        message: data.appointments[i].errorMessage,
                    } as ErrorDetail;
                }
            }

            return data;
        }
        catch (error: any) {
            throw error;
        }
    },
    get: async (id: string, signal?: AbortSignal): Promise<GetDraftResponse> => {
        try {
            const { data } = await api.get<GetDraftResponse>(`${PATH_DRAFT}/draft/${id}`, { signal });

            for (let i = 0; i < data.appointments.length; i++) {
                if (data.appointments[i].errorCode != null && (draftExceptionCodes[data.appointments[i].errorCode as string] != null)) {
                    throw {
                        code: data.appointments[i].errorCode,
                        message: data.appointments[i].errorMessage
                    } as ErrorDetail;
                }
            }

            return data;
        }
        catch (error: any) {
            throw error;
        }
    },
    getByAuthenticatedUser: async (signal?: AbortSignal): Promise<SearchDraftResponse[]> => {
        try {
            const { data } = await api.get<SearchDraftResponse[]>(`${PATH_DRAFT}/draft`, { signal });

            return data.filter((draft) => draft.json != null);
        }
        catch (error: any) {
            throw error;
        }
    },
    interact: async (id: string, license: string, body: string, signal?: AbortSignal): Promise<CreateUpdateDraftResponse> => {
        try {
            const { data } = await api.put<CreateUpdateDraftResponse>(`${PATH_DRAFT}/${license}/draft/${id}/interact`, body, { signal });

            return data;
        }
        catch (error: any) {
            throw error;
        }
    },
    discard: async (id: string, license: string, signal?: AbortSignal): Promise<ArchiveUnarchiveResponse> => {
        try {
            const { data } = await api.delete<ArchiveUnarchiveResponse>(`${PATH_DRAFT}/${license}/draft/${id}`, { signal });

            return data;
        }
        catch (error: any) {
            throw error;
        }
    },
    getDraftFromAppointment: async (id: string, request: CreateDraftFromAppointmentRequest, signal?: AbortSignal): Promise<CreateUpdateDraftResponse> => {
        try {
            const { data } = await api.post<CreateUpdateDraftResponse>(`${PATH_DRAFT}/${request.organizationalUnitKey}/appointment/${id}/draft`, request, { signal });

            return data;
        }
        catch (error: any) {
            throw error;
        }
    },

};

export default DraftApi;
