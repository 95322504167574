import { useFormContext, Controller } from 'react-hook-form';

import { Checkbox, FormControlLabel } from '@mui/material';

import { useFormMode } from '../../../hooks';

import WiCheckboxProps from './types/WiCheckboxProps';


const WiCheckbox: React.FC<WiCheckboxProps> = (props) => {

    const { 
        name, 
        ...other 
    } = props;

    const { control } = useFormContext();
    const { isView } = useFormMode();

    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Checkbox 
                            {...field} 
                            checked={field.value}
                            disabled={other.disabled || isView}
                        />
                    )}
                />
            }
            {...other}
        />
    );
}

export default WiCheckbox;