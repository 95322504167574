

const WiAvatarService = {

    formatUsernameInitials: (name: string, maxSize: number): string => {
        if(name === null) return '';

        let initials = name
            .toUpperCase()
            .split(" ")
            .map(str => str[0])
            .join("");

        return initials.length > maxSize ? initials.substring(0, maxSize) : initials;
    }

}

export default WiAvatarService;