import { ExceptionCodes } from "@wi-core/shared-blocks";


const reservationExceptionCodes: ExceptionCodes = {
    //create_reservation
    "SC UC142 0001": "create_reservation.invalid_license_key_error",
    "SC UC142 0002": "create_reservation.organizational_unit_key_error",
    "SC UC142 0003": "create_reservation.organizational_unit_authorization_error",
    "SC UC142 0004": "create_reservation.license_authorization_error",
    "SC UC142 0029": "create_reservation.tags_not_found_error",
    "SC UC142 0030": "create_reservation.tag_license_authorization_error",
    "SC UC142 0031": "create_reservation.tag_classification_error",
    "SC UC142 0032": "create_reservation.person_not_found_error",
    "SC UC142 0033": "create_reservation.disponibilities_not_found_error",
    "SC UC142 0035": "create_reservation.type_not_allowed",

    //search_reservation
    "SC UC143 0001": "search_reservation.field_licensekey_required",
    "SC UC143 0002": "search_reservation.field_startat_required",
    "SC UC143 0003": "search_reservation.field_endat_required",
    "SC UC143 0004": "search_reservation.organizational_unit_forbidden",

    //cancel_reservation
    "SC UC145 0001": "cancel_reservation.reservation_not_found",
    "SC UC145 0002": "cancel_reservation.reason_not_found",
    "SC UC145 0003": "cancel_reservation.reason_not_allowed",
    "SC UC145 0004": "cancel_reservation.reservation_status_not_allowed",

    //transfer_reservation
    "SC UC146 0001": "transfer_reservation.reservation_not_found",
    "SC UC146 0002": "transfer_reservation.reservation_owner_not_match",
    "SC UC146 0003": "transfer_reservation.cant_transfer_for_current_owner",
    "SC UC146 0004": "transfer_reservation.not_able_to_transfer",

    //get_reservation
    "SC UC144 0001": "get_reservation.reservation_not_found",
    "SC UC144 0002": "get_reservation.license_forbidden",
  
}

export default {
    ...reservationExceptionCodes
};
