import { useEffect, useState } from 'react';
import {
    ROLES,
    StatusFilter,
    TagClassification,
    useOrganizationalUnitByRoles,
    useProfessional,
    useRoom,
    useSearchLicenseByRoles,
    useTag,
    useUser
} from '@wi-core/shared-blocks';
import { useForm } from 'react-hook-form';
import { ReservationFilterType } from '../../../../../@types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReservationFilterSchema } from '../hooks';

const  useReservationFilter = (initialFilter: ReservationFilterType) => {
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const { schema } = useReservationFilterSchema();

    const methods = useForm<ReservationFilterType>({
        resolver: yupResolver(schema),
        defaultValues: initialFilter
    });

    const { handleSubmit, watch, reset, setValue } = methods;

    const watchLicense = watch('license');
    const watchOrganizationalUnitKey = watch('organizationalUnits');

    const { loadingLicenses, licensesToAutocomplete } = useSearchLicenseByRoles([ROLES.SUPER_ADMIN]);

    const { organizationalUnits, organizationalUnitsToAutocomplete, loadingOrganizationalUnits } =
        useOrganizationalUnitByRoles(
            [ROLES.SUPER_ADMIN, ROLES.RESERVATION_MANAGER, ROLES.RESERVATION_VIEWER], //TODO: verificar permissao
            false,
            watchLicense ? watchLicense.id : undefined
        );

    const {
        professionalsToAutocomplete,
        loadingProfessionals,
        handleFilterProfessionalsByLicenseKey,
        handleFilterProfessionalsByName
    } = useProfessional(false, true);

    const { roomToAutocomplete, loadingRoom, handleFilterRoomByName, handleFilterroomByOrganizationalUnitKey } =
        useRoom(false);

    const { tags, loadingTags, totalTags, handleFilterTagsByName } = useTag(true, watchLicense?.id, StatusFilter.Ok, [
        TagClassification.Appointment,
        TagClassification.Disponibility
    ]);

    const { usersToAutocomplete, loadingUsers, handleFilterUserByName } = useUser();

    useEffect(() => {
        if (watchLicense) {
            handleSelectLicense(watchLicense?.id);
        }

        setValue('professional', null);
    }, [watchLicense]);

    useEffect(() => {
        if (watchOrganizationalUnitKey) {
            handleFilterroomByOrganizationalUnitKey(
                watchOrganizationalUnitKey.map(organizationalUnit => organizationalUnit.id).join(',')
            );
        }
    }, [watchOrganizationalUnitKey]);

    const handleSelectLicense = (value: string) => {
        handleFilterProfessionalsByLicenseKey(value);
    };

    const handleShowFilter = () => {
        setShowFilter(!showFilter);
    };

    return {
        organizationalUnits,
        organizationalUnitsToAutocomplete,
        loadingOrganizationalUnits,

        loadingLicenses,
        licensesToAutocomplete,

        professionalsToAutocomplete,
        loadingProfessionals,
        handleFilterProfessionalsByName,

        roomToAutocomplete,
        loadingRoom,
        handleFilterRoomByName,

        watchLicense,
        methods,
        watch,
        handleSubmit,

        tags,
        loadingTags,
        totalTags,
        handleFilterTagsByName,

        showFilter,
        handleShowFilter,

        usersToAutocomplete,
        loadingUsers,
        handleFilterUserByName
    };
};

export default useReservationFilter;
