import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useI18n } from '@wi-core/ui';
import { CancelReservationRequest, ErrorDetail, reservationExceptionCodes } from '@wi-core/shared-blocks';
import { ReservationCancelFormType } from '../../@types';
import { useCancelReservation } from '@wi-core/shared-blocks/src/resources/reservation/hooks/useCancelReservation';
import i18n from '../../i18n';

const userReservationCancelManager = (onClose: VoidFunction) => {

    const { translate, lang } = useI18n();
    const { enqueueSnackbar } = useSnackbar();
    
    const cancelMutation = useCancelReservation();

    const [loadingManager, setLoadingManager] = useState<boolean>(false);

    const onSubmit = async (reservationId: string, organizationalUnitKey: string, data: ReservationCancelFormType)=> {
        setLoadingManager(true);

        const cancelReservationRequest: CancelReservationRequest = {
            id: reservationId,
            justification: data.justification,
            reasonId: data.reason ? data.reason.id : ''
        };

        try {
            const response = await cancelMutation.mutate({ organizationalUnitKey: organizationalUnitKey, id: reservationId, data: cancelReservationRequest });
            enqueueSnackbar(i18n[lang.language].success_canceling);
            setLoadingManager(false);
            onClose();
        }
        catch (error: any) {
            enqueueSnackbar(
                translate(reservationExceptionCodes[error.code] || 'common.update_error'),
                { variant: 'error' }
            );
            enqueueSnackbar(translate(i18n[lang.language].error.error_canceling), { variant: 'error' });
            setLoadingManager(false);
        }

    };

    return {
        onSubmit,
        loadingManager
    };
};

export default userReservationCancelManager;
