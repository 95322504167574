import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";
import { useSnackbar } from "notistack";

import { 
    AddressApi,
    SearchCitiesRequest, 
    SearchCountriesRequest, 
    SearchLocalizationByZipCodeResponse, 
    SearchStatesRequest 
} from "@wi-core/shared-blocks";

import useI18n from "../../../hooks/useI18n";

import i18n from "../i18n";

import useWiAddressForm from "./useWiAddressForm";


let debounce: ReturnType<typeof setTimeout>;

const useSearch = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { lang } = useI18n();
    
    const { watch } = useFormContext();
    const { setFormValues, cleanFields } = useWiAddressForm();

    const country = watch('address.country');
    const state = watch('address.state');
    const city = watch('address.city');
    const zipCode = watch('address.zipCode');

    const [countries, setCountries] = useState<WiAutocompleteOption[]>([]);
    const [states, setStates] = useState<WiAutocompleteOption[]>([]);
    const [cities, setCities] = useState<WiAutocompleteOption[]>([]);

    const [isInit, setIsInit] = useState<boolean>(true);

    const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
    const [loadingStates, setLoadingStates] = useState<boolean>(false);
    const [loadingCities, setLoadingCities] = useState<boolean>(false);
    const [laodingLocalization, setLaodingLocalization] = useState<boolean>(false);

    const [searchDisabled, setSearchDisabled] = useState<boolean>(false);

    //#region useEffect

    useEffect(() => {
        searchCountries();
        searchStates(country ? { countryId: country.id } : {});
        searchCities(state ? { stateId: state.id } : {});
    }, [country, state, city]);


    useEffect(() => {
        if (isInit) return setIsInit(false);

        clearTimeout(debounce);

        if (!zipCode) return;

        debounce = setTimeout(() => {
            searchLocalizationByZipCode();
        }, 1000);

    }, [zipCode]);

    //#endregion


    //#region Search

    const searchCountries = (query?: SearchCountriesRequest) => {
        if(searchDisabled || country) return;

        setLoadingCountries(true);
        setCountries([]);

        AddressApi.searchCountries(query)
            .then((result) => {
                setCountries(result.data.content.map(e => ({id: e.id, label: e.name})));
            })
            .catch(() => {
                setCountries([]);
            })
            .finally(() => {
                setLoadingCountries(false);
            })
    }

    const searchStates = (query?: SearchStatesRequest) => {
        if(searchDisabled || state) return;

        setLoadingStates(true);
        setStates([]);
        
        AddressApi.searchStates(query)
            .then((result) => {
                setStates(result.data.content.map(e => ({id: e.id, label: e.name})));
            })
            .catch(() => {
                setStates([]);
            })
            .finally(() => {
                setLoadingStates(false);
            })        
    }

    const searchCities = (query?: SearchCitiesRequest) => {
        if(searchDisabled || city) return;

        setLoadingCities(true);
        setCities([]);

        AddressApi.searchCities(query)
            .then((result) => {
                setCities(result.data.content.map(e => ({id: e.id, label: e.name})));
            })
            .catch(() => {
                setCities([]);
            })
            .finally(() => {
                setLoadingCities(false);
            })
    }

    const searchLocalizationByZipCode = () => {
        setLaodingLocalization(true);
        
        AddressApi.searchLocalizationByZipCode({ cep: zipCode })
            .then((result) => {
                setSearchDisabled(true);
                setOptions(result.data);
                setFormValues(result.data);
                setSearchDisabled(false);
            })
            .catch((error) => {
                const errorMessage = i18n[lang.language].error[error.code === 'AD UC004 0001' ? 'service_not_available' : 'zip_code_not_found'];

                if(errorMessage !== null) {
                    enqueueSnackbar(errorMessage, { variant: 'warning' });
                }
                
                cleanFields();
            })
            .finally(() => {
                setLaodingLocalization(false);
            })
    }

    //#endregion


    //#region Input events

    const onInputChangeCountry = (value: string) => {
        searchCountries({ keyword: value })
    }

    const onInputChangeState = (value: string) => {
        searchStates({
            keyword: value,
            countryId: country ? country.id : null
        });
    }

    const onInputChangeCity = (value: string) => {
        searchCities({
            keyword: value,
            stateId: state ? state.id : null
        });
    }

    //#endregion


    //#region help

    const setOptions = (data: SearchLocalizationByZipCodeResponse) => {
        if(!data || data.error) return;

        setCountries([{id: data.city.state.country.id, label: data.city.state.country.name}]);
        setStates([{id: data.city.state.id, label: data.city.state.name}]);
        setCities([{id: data.city.id, label: data.city.name}]);
    }
    //#endregion


    return {
        countries,
        states,
        cities,

        loadingCountries,
        loadingStates,
        loadingCities,
        laodingLocalization,

        onInputChangeCountry,
        onInputChangeState,
        onInputChangeCity
    };
}


export default useSearch;