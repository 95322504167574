import api from '@wi-config/axios';
import { PaginationResponse } from '@wi-core/shared-blocks';
import { AxiosResponse } from 'axios';

import SearchUserRequest from '../@types/search-user/SearchUserRequest';
import SearchUserResponse from '../@types/search-user/SearchUserResponse';


const PATH_USER: string = 'api/v1/accounts';

const UserApi = {
    search: (data?: SearchUserRequest): Promise<AxiosResponse<PaginationResponse<SearchUserResponse>>> => {
        return api.get(`${PATH_USER}/search`, { params: data });
    }
};

export default UserApi;
