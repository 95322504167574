// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';

import { useI18n } from '@wi-core/ui';

import AppBannerProps from './types/AppBannerProps';
import { WiAppLogo } from '../../../../../components';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------


const AppBanner: React.FC<AppBannerProps> = (props) => {

    const {
        appName,
        licenseName,
        icon,
        isCollapse
    } = props;

    const {translate} = useI18n();

    return (
        <Link underline="none" color="inherit">
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: 'transparent',
                    }),
                }}
            >
                <WiAppLogo 
                    icon={icon} 
                    sx={{
                        width: 48, 
                        height: 48, 
                        minWidth: 48, 
                        minHeight: 48
                    }}
                />

                <Box
                    sx={{
                        ml: 2,
                        ...(
                            isCollapse && {
                                ml: 0,
                                width: 0,
                            }
                        ),
                        ...(
                            !isCollapse && {
                                width: 'calc(100% - 64px)',
                            }
                        )
                    }}
                >
                    <Typography variant="subtitle1" noWrap sx={{ textOverflow: 'ellipsis' }}>
                        {translate(appName)}
                    </Typography>
                    {licenseName && <Typography variant="body2" noWrap sx={{ color: 'text.secondary', textOverflow: 'ellipsis'}}>
                        {translate(licenseName)}
                    </Typography>}
                </Box>
            </RootStyle>
        </Link>
    );
}

export default AppBanner;