import { AxiosResponse } from "axios";
import api from "@wi-config/axios";

import ListLicensesByRolesRequest from "../@types/list-licenses-by-roles/ListLicensesByRolesRequest";
import ListLicensesByRolesResponse from "../@types/list-licenses-by-roles/ListLicensesByRolesResponse";



const PATH_LICENSES: string = 'api/v1/administration/licenses';

const LicenseApi = {

    listByRoles: (data?: ListLicensesByRolesRequest): Promise<AxiosResponse<ListLicensesByRolesResponse>> => {
        return api.get(`${PATH_LICENSES}/by-roles`, { params: data });
    }

}


export default LicenseApi;