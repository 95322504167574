import * as Yup from 'yup';

const useReservationFilterSchema = () => {

    const schema = Yup.object().shape({
        startAt: Yup.date()
            .required('common.error.required_field'),
        endAt: Yup.date()
            .required('common.error.required_field'),
        organizationalUnits: Yup.array()
            .required('common.error.required_field')
            .nullable(),
        license: Yup.object()
            .required('common.error.required_field')
            .nullable(),
        professional: Yup.object().notRequired().nullable(),
        room: Yup.object().notRequired().nullable(),
        personName: Yup.string().notRequired().nullable(),
        personBirthDate: Yup.date().notRequired().nullable(),
        personDocument: Yup.string().notRequired().nullable(),
        statuses: Yup.array().notRequired().nullable(),
        procedureName: Yup.string().notRequired().nullable(),
        user: Yup.object().notRequired().nullable(),
    });

    return {
        schema
    };
};

export default useReservationFilterSchema;
