import { useState } from 'react';
import SearchFilter from './@types/SearchFilter';

export type UseTableProps = {
    dense?: boolean;
    order?: 'asc' | 'desc';
    orderBy?: string;
    selected?: string[];
    rowsPerPage?: number;
    currentPage?: number;
    [key: string]: any;
};

const defaultValues: SearchFilter = {
    keyword: null,
    page: 0,
    order: 'asc',
    orderBy: 'name',
    rowsPerPage: 5
};

const useTable = (props?: UseTableProps) => {
    const [filter, setFilter] = useState<SearchFilter>({
        ...defaultValues,
        ...props
    });

    const onSearch = (value: {[key:string] : any }) => {
        setFilter({
            ...filter,
            ...value,
            page: 0
        });
    }

    const onSort = (orderBy: string) => {
        const isAsc = filter.orderBy === orderBy && filter.order === 'asc';
        if (orderBy !== '') {
            setFilter({
                ...filter,
                order: isAsc ? 'desc' : 'asc',
                orderBy
            });
        }
    };

    const onChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFilter({
            ...filter,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
    };

    const setPage = (page: number) => {
        setFilter({
            ...filter,
            page
        });
    };

    return {
       ...filter,
        filter,

        onSort,
        onChangePage,
        onChangeRowsPerPage,
        setPage,
        onSearch
    };
};

export default useTable;

export function emptyRows(
    page: number,
    rowsPerPage: number,
    arrayLength: number
) {
    return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}
