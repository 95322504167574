import { useFormContext, Controller } from 'react-hook-form';
import { Box, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { useI18n } from '@wi-core/ui';

import { useFormMode } from '../../../hooks';

import WiTextFieldProps from './types/WiTextFieldProps';
import WiTextMask from '../../wi-taxt-mask/WiTextMask';


const WiTextField: React.FC<WiTextFieldProps & TextFieldProps> = (props) => {

    const {
        name,
        mask,
        definitions,
        onAccept,
        ...other
    } = props;

    const { control } = useFormContext();
    const { isView } = useFormMode();
    const { translate } = useI18n();

    const buildInputProps = () => {
        if (!mask) return {...other.InputProps};

        return {
            inputComponent: WiTextMask as any,
            inputProps: {
                mask,
                definitions,
                onAccept: onAccept
            },
            ...other.InputProps
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Box sx={{ display: 'flex', flexDirection: 'column', flex:1 }}>
                    <TextField
                        {...field}
                        fullWidth
                        error={!!error}
                        disabled={other.disabled || isView}
                        {...other}
                        InputProps={buildInputProps()}
                    />
                    <FormHelperText error sx={{ m: '4px 8px 0 8px', minHeight: '8px' }}>
                        {translate(error?.message || '')}
                    </FormHelperText>
                </Box>
            )}
        />
    );
}


export default WiTextField;
