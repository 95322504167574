import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useI18n } from "@wi-core/ui";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";

import OrganizationalUnitApi from "../api/OrganizationalUnitApi";
import OrganizationalUnitByRole from "../@types/organizational-unit-by-role/OrganizationalUnitByRole";

const useOrganizationalUnitByRoles = (roles: string[], licenseRequired: boolean, licenseKey?: string) => {

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [organizationalUnits, setOrganizationalUnits] = useState<OrganizationalUnitByRole[]>([]);
    const [organizationalUnitsToAutocomplete, setOrganizationalUnitsToAutocomplete] = useState<WiAutocompleteOption[]>([]);
    const [loadingOrganizationalUnits, setLoadingOrganizationalUnits] = useState<boolean>(false);

    useEffect(() => {
        if(licenseRequired && licenseKey == null) return;

        searchOrganizationalUnits();
    }, [licenseKey]);

    const searchOrganizationalUnits = () => {

        setLoadingOrganizationalUnits(true);

        const search = {
            roles: roles.join(','),
            licenseKey
        };

        OrganizationalUnitApi.listByRoles(search)
            .then(result => {

                const {
                    data: { organizationalUnits }
                } = result;

                setOrganizationalUnits(organizationalUnits);
                setOrganizationalUnitsToAutocomplete(
                    organizationalUnits.map(e => {
                        return {
                            id: e.key,
                            label: e.name,
                            licenseKey: e.licenseKey,
                            data: e
                        }
                    })
                );
            })
            .catch(() => {
                setOrganizationalUnits([]);
                setOrganizationalUnitsToAutocomplete([]);

                enqueueSnackbar(
                    translate('common.search_error.error_loading_organizational_units'),
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setLoadingOrganizationalUnits(false);
            });
    };

    return {
        organizationalUnits,
        organizationalUnitsToAutocomplete,
        loadingOrganizationalUnits,
        searchOrganizationalUnits
    };
}

export default useOrganizationalUnitByRoles;
