import { GetUserRolesByIdResponse } from '@wi-core/shared-blocks';
import WiPermissionContextType from '../@types/WiPermissionContextType';

const InitialValues: WiPermissionContextType = {
    getCurrentUser: () => null,
    hasPermission: (roles: string[]) => false,
    hasPermissionLicenses: (licensesKey: string[], roles: string[]) => false,
    hasPermissionOrganizationalUnits: (organizationalUnitsKey: string[], roles: string[]) => false,
    getCurrentUserForAutoComplete: () => null,
    getPermissionLicenseForAutoComplete: () => null,
    getPermissionOrganizationalUnitForAutoComplete: () => []
};

export default InitialValues;
