import React from "react";
import { Autocomplete, Box, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useDebounce } from "@wi-core/ui";

import WiAutocompleteMultipleNoFormProps from "./@types/WiAutocompleteMultipleNoFormProps";
import WiAutocompleteOption from "../wi-autocomplete-no-form/@types/WiAutocompleteOption";
import WiLabel from "../../wi-label/WiLabel";


const WiAutocompleteMultipleNoForm = (props: WiAutocompleteMultipleNoFormProps) => {

    const {
        value,
        label,
        placeholder,
        loading,
        required,
        disabled,
        error,
        options,
        inputChangeDebounceTime,
        onInputChange,
        onChange,
        sx,
        readOnly,
    } = props;

    const { debounce } = useDebounce();

    return (
        <Autocomplete
            sx={{ '.MuiOutlinedInput-root': { p: '6px !important' }, ...sx }}
            size="small"
            multiple
            fullWidth
            value={value}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id
            }}
            getOptionLabel={(option) => option.label}
            onChange={(event, values) => onChange(values)}
            onInputChange={(event, value) => {
                if (!onInputChange) return;

                debounce(() => {
                    onInputChange(value);
                }, inputChangeDebounceTime || 500);
            }}
            readOnly={readOnly}
            renderTags={(values: WiAutocompleteOption[], getTagProps) => {
                return values.map((option, index) => (
                    <WiLabel
                        color="primary"
                        key={option.id}
                        sx={{ m: '2px' }}
                    >
                        <Typography variant={'subtitle2'} noWrap>
                            {option.label}
                        </Typography>
                    </WiLabel>
                ))
            }}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={!!error}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    sx={{
                        pointerEvents: disabled ? 'none' : 'auto',
                        '.MuiAutocomplete-input': {
                            minWidth: '20px',
                            marginRight: '56px'
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <Box sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: loading ? '10px' : '20px',
                            minWidth: '56px',
                            display: 'flex',
                            flexDirection: 'column',
                            '.MuiAutocomplete-endAdornment': {
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }
                        }}>
                            <Stack flex={1} flexDirection="row" spacing={2} alignItems="center">
                                {loading && <Stack flexDirection="row" alignItems="center">
                                    <CircularProgress color="inherit" size={20} />
                                </Stack>}
                                {!disabled && <Stack flexDirection="row" alignItems="center">
                                    {params.InputProps.endAdornment}
                                </Stack>}
                            </Stack>
                        </Box>
                    }}
                />
            )}
        />
    );
}

export default WiAutocompleteMultipleNoForm
