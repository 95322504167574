import { ExceptionCodes } from "@wi-core/shared-blocks";


const disponibilityExceptionCodes: ExceptionCodes = {
    
}

export default {
    ...disponibilityExceptionCodes
};
