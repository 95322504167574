//LIBS
import React from "react";
import { TimelineContent } from "@mui/lab";
import { Paper, Stack, Typography } from "@mui/material";
import { IsVisible, WiDescriptionItem, useI18n } from "@wi-core/ui";
import { ReservationHistoryType } from "@wi-core/shared-blocks";
import i18n from '../../i18n';

//TYPES
import ReservationHistoryTimeineContentProps from "./@types/ReservationHistoryTimeineContentProps";


const ReservationHistoryTimeineContent = ({ history }: ReservationHistoryTimeineContentProps) => {

    const { lang } = useI18n();

    const name = history.type === ReservationHistoryType.Transfered ? history.toUsername : history.byUsername;

    return (
        <TimelineContent sx={{ my: '4px' }}>
            <Paper
                sx={{
                    p: 3,
                    display: 'grid',
                    rowGap: 1,
                    bgcolor: 'background.neutral'
                }}
            >
                <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle1" component="span">
                        {i18n[lang.language][history.type]}
                    </Typography>
                    <Typography component="span">{name}</Typography>
                    <IsVisible when={history.justification !== null}>
                        <WiDescriptionItem label={i18n[lang.language].justification} lineBreak>
                            {history.justification || '-'}
                        </WiDescriptionItem>
                    </IsVisible>
                </Stack>
            </Paper>
        </TimelineContent>
    )
}

export default ReservationHistoryTimeineContent;
