import React, { useEffect } from 'react';

import Env from '@wi-config/env';
import { useAuth, hasAuthParams } from "react-oidc-context";
import WiAuthGuardProps from './types/WiAuthGuardProps';
import { WiLoadingScreen } from '@wi-themes/minimal-override';

const WiAuthGuard: React.FC<WiAuthGuardProps> = ({ children }) => {

    const auth = useAuth();

    useEffect(() => {    
        window.addEventListener('storage', onStorageChanged)
        return () => window.removeEventListener('storage', onStorageChanged)
    }, [])

    const onStorageChanged = (event: StorageEvent) => {
        const key = `oidc.user:${Env.oidcConfig.authority}:${Env.oidcConfig.client_id}`;
        if(event.key === key && event.newValue === null) {
            location.pathname = Env.loginPath;
        }
    }

    if (auth.isLoading) {
        return <WiLoadingScreen />;
    }

    if (auth.error && auth.error.message === 'Session not active') {
        auth.signoutRedirect();
    }

    if(auth.isAuthenticated) {
        return children;
    }

    location.pathname = Env.loginPath;

    return <WiLoadingScreen />;
};

export default WiAuthGuard;