enum ReasonClassification {
    Appointment = 'Appointment',
    Reservation = 'Reservation',
    // Disponibility = 'Disponibility',
    // Indisponibility = 'Indisponibility',
    // Attendance = 'Attendance'
}


export default ReasonClassification;
