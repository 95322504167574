import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import { List, Stack, Button, IconButton, ListItemText, ListItem } from '@mui/material';
import { useI18n } from '@wi-core/ui';
import Image from '@wi-themes/minimal/src/components/Image';
import Iconify from "@wi-themes/minimal/src/components/Iconify";
import { varFade } from '@wi-themes/minimal/src/components/animate';

import UploadMultiFileProps from '../upload-multi-file/types/UploadMultiFileProps';
import { useFormatNumber } from '../../../../hooks';
import i18n from '../../i18n';


export default function MultiFilePreview({
    showPreview = false,
    files,
    onRemove,
    onRemoveAll,
}: UploadMultiFileProps) {

    const { lang } = useI18n();
    const { fData } = useFormatNumber();

    const hasFile = files.length > 0;

    return (
        <>
            <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
                <AnimatePresence>
                    {files.map((file) => {
                        if (showPreview) {
                            return (
                                <ListItem
                                    key={file.key}
                                    component={m.div}
                                    {...varFade().inRight}
                                    sx={{
                                        p: 0,
                                        m: 0.5,
                                        width: 80,
                                        height: 80,
                                        borderRadius: 1.25,
                                        overflow: 'hidden',
                                        position: 'relative',
                                        display: 'inline-flex',
                                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                                        opacity: '1 !important'
                                    }}
                                >
                                    <Image alt="preview" src={file.content} ratio="1/1" />
                                    <IconButton
                                        size="small"
                                        onClick={() => onRemove(file)}
                                        sx={{
                                            top: 6,
                                            p: '2px',
                                            right: 6,
                                            position: 'absolute',
                                            color: 'common.white',
                                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                            '&:hover': {
                                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                            },
                                        }}
                                    >
                                        <Iconify icon={'eva:close-fill'} />
                                    </IconButton>
                                </ListItem>
                            );
                        }

                        return (
                            <ListItem
                                key={file.key}
                                component={m.div}
                                {...varFade().inRight}
                                sx={{
                                    my: 1,
                                    px: 2,
                                    py: 0.75,
                                    borderRadius: 0.75,
                                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                                }}
                            >
                                <Iconify
                                    icon={'eva:file-fill'}
                                    sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
                                />

                                <ListItemText
                                    primary={file.name}
                                    primaryTypographyProps={{ variant: 'subtitle2' }}
                                    secondaryTypographyProps={{ variant: 'caption' }}
                                />

                                <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                                    <Iconify icon={'eva:close-fill'} />
                                </IconButton>
                            </ListItem>
                        );
                    })}
                </AnimatePresence>
            </List>

            {hasFile && (
                <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                    <Button color="inherit" size="small" onClick={onRemoveAll}>
                        {i18n[lang.language].multi_file_preview.button_remove_all}
                    </Button>
                </Stack>
            )}
        </>
    );
}
