import { useEffect, useState } from 'react';
import { Stack, TextField } from '@mui/material';

import WiInputDDI from '../../wi-input-ddi/WiInputDDI';
import WiTextMask from '../../wi-taxt-mask/WiTextMask';
import WiAutocompleteOption from '../wi-autocomplete-no-form/@types/WiAutocompleteOption';
import WiPhoneFieldNoFormProps from './@types/WiPhoneFieldNoFormProps';

const DEAFULT_MASK = '##############################';

const WiPhoneFieldNoForm = (props: WiPhoneFieldNoFormProps) => {
    const {
        label,
        error,
        disabled,
        required,
        valueDdi,
        valueNumber,
        onChangeDdi,
        onChangeNumber,
        optionsDDI
    } = props;

    const [ddi, setDdi] = useState<WiAutocompleteOption | null>(null);
    const [listDdi, setListDdi] = useState<WiAutocompleteOption[]>([]);

    useEffect(() => {
        initDdi(listDdi);
    }, [valueDdi]);

    useEffect(() => {
        const newListDDI = optionsDDI && optionsDDI.length > 0 ? optionsDDI : [];

        setListDdi(newListDDI);
        initDdi(newListDDI);
    }, [optionsDDI]);

    const initDdi = (optionsDDI: WiAutocompleteOption[]) => {
        if(!valueDdi) {
            return setDdi(null);
        }

        if(!ddi || valueDdi !== ddi.data.ddi.trim()) {
            const newDdi = optionsDDI.find(e => e.data.ddi.trim() === valueDdi.trim());
            return setDdi(newDdi || null);
        }
    }

    const handleChangeDDI = (value: WiAutocompleteOption | null) => {
        setDdi(value || null);
        onChangeDdi(value && value.data.ddi);
    };

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeNumber(event.target.value);
    };

    return (
        <Stack direction="row" spacing={1}>
            <WiInputDDI
                value={ddi}
                options={listDdi}
                onChangeDDI={handleChangeDDI}
                required={required}
                error={error}
            />
            <TextField
                fullWidth
                label={label}
                size="small"
                error={error}
                disabled={disabled}
                required={required}
                value={valueNumber}
                onChange={handleChangePhone}
                InputProps={{
                    inputComponent: WiTextMask as any,
                    inputProps: {
                        mask: ddi?.data?.ddiMasks || DEAFULT_MASK,
                        definitions: { '#': /[0-9]/ }
                    }
                }}
            />
        </Stack>
    );
};

export default WiPhoneFieldNoForm;
