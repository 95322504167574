import { Outlet } from "react-router-dom";
import { Box, useTheme } from "@mui/material";

import Header from "../components/header/Header";

import { HEADER } from "../../config";

const WiHeaderOnlyLayout = () => {

    const theme = useTheme();
    const themePaletteGrey = theme.palette.grey[500_8];

    return (
        <>
            <Header 
                verticalLayout={true} 
                hideQuickAccessMenu={true}
                hideModulesMenuButton={true}
            />

            <Box
                component="main"
                sx={{
                    minHeight: { lg: 1 },
                    background: themePaletteGrey,
                    px: { lg: 2 },
                    pt: {
                        xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                        lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
                    },
                    pb: {
                        xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                        lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
                    },
                }}
            >
                <Outlet />
            </Box>
        </>
    );
}


export default WiHeaderOnlyLayout;
