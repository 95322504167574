import { Stack } from '@mui/material';

import { WiAutocompleteOption } from '../../wi-inputs-no-form';
import WiAutocomplete from '../wi-autocomplete/WiAutocomplete';
import WiProfessionalAutocompleteProps from './@types/WiProfessionalAutocompleteProps';


const WiProfessionalAutocomplete = (props: WiProfessionalAutocompleteProps) => {
    const {
        name,
        label,
        options,
        loading,
        disabled,
        sx,
        onInputChange
    } = props;

    const getLabel = (option: WiAutocompleteOption) => {
        if (option.data.regionalCouncilNumber) {
            return `[${option.data.regionalCouncilNumber}/${option.data.regionalCouncilStateAcronym}] - ${option.label}`;
        }

        return option.label;
    }

    return (
        <WiAutocomplete
            name={name}
            label={label}
            options={options}
            loading={loading}
            disabled={disabled}
            onInputChange={onInputChange}
            sx={sx}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: WiAutocompleteOption) => {
                return (
                    <Stack component="li" {...props} key={option.id}>
                        <Stack direction="row" width="100%">
                            {getLabel(option)}
                        </Stack>
                    </Stack>
                );
            }}
            filterOptions={(options, input) => {
                return options.filter(option => {
                    return (
                        option.data.regionalCouncilNumber.includes(input.inputValue) ||
                        option.label.toLowerCase().includes(input.inputValue.toLowerCase())
                    );
                });
            }}
        />
    );
};

export default WiProfessionalAutocomplete;
