import { Autocomplete, Box, debounce, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import WiInputDDIProps from './@types/WiInputDDIProps';

const WiInputDDI = (props: WiInputDDIProps) => {

    const {
        options,
        value,
        required,
        error,
        onChangeDDI,
    } = props;

    const [optionsDDISearch, setOptionsDDISearch] = useState(options);

    useEffect(() => {
        setOptionsDDISearch(options);
    }, [options]);

    return (
        <Autocomplete
            options={optionsDDISearch}
            sx={{
                '.MuiOutlinedInput-root': { p: '6px !important' },
                minWidth: '120px',
                width: '120px',
                maxWidth: '120px',
                mr: '8px'
            }}
            size="small"
            autoHighlight
            value={value}
            onChange={(event, value) => onChangeDDI(value)}
            loading={options.length === 0}
            getOptionLabel={option => option.data.ddi}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            componentsProps={{
                paper: {
                    sx: {
                        width: '320px'
                    }
                }
            }}
            filterOptions={(options, input) => {
                return options.filter(option => {
                    return option.data.ddi.toLowerCase().includes(input.inputValue.toLowerCase()) ||
                        option.data.acronym.toLowerCase().includes(input.inputValue.toLowerCase()) ||
                        option.label.toLowerCase().includes(input.inputValue.toLowerCase())
                });
            }}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{
                        '& > img': { mr: 2, flexShrink: 0 },
                        width: '300px'
                    }}
                    {...props}
                    key={option.id}
                >
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.data.acronym.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.data.acronym.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label} ({option.data.acronym}) {option.data.ddi}
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label="DDI"
                    required={required}
                    error={error}
                    autoComplete='off'
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
};
export default WiInputDDI;
