import { useFormContext, Controller } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    SelectProps,
    Typography
} from '@mui/material';
import { useI18n } from '@wi-core/ui';

import { useFormMode } from '../../../hooks';

import WiSelectProps from './@types/WiSelectProps';

const WiSelect: React.FC<WiSelectProps> = props => {
    const {
        name,
        options = {},
        label,
        placeholder,
        size,
        required,
        disabled,
        defaultValue,
        showCleanOption,
        sx,
        enumOptions,
        initialLabelEnumOptions
    } = props;

    const { control } = useFormContext();
    const { isView } = useFormMode();
    const { translate } = useI18n();

    if (Object.keys(options).length === 0 && enumOptions && Object.keys(enumOptions).length > 0) {
        for (const val in enumOptions) {
            options[val] = `${initialLabelEnumOptions}.${val}`;
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
                    <FormControl size={size}>
                        {label && (
                            <InputLabel id="salect-label" required={required} error={!!error}>
                                {label}
                            </InputLabel>
                        )}
                        <Select
                            {...field}
                            fullWidth
                            defaultValue={defaultValue}
                            displayEmpty
                            label={label}
                            size={size}
                            error={!!error}
                            disabled={disabled || isView}
                            required={required}
                            renderValue={key => {
                                if (!key && placeholder) {
                                    return <Typography sx={{ opacity: 0.5 }}>{placeholder}</Typography>;
                                }

                                return translate(options[key]);
                            }}
                        >
                            {showCleanOption && (
                                <MenuItem value={''} key={'clean-option'}>
                                    <em>{translate('common.clean')}</em>
                                </MenuItem>
                            )}
                            {Object.keys(options).map(key => (
                                <MenuItem value={key} key={key}>
                                    {translate(options[key])}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormHelperText error sx={{ m: '4px 8px 0 8px', minHeight: '8px' }}>
                        {translate(error?.message || '')}
                    </FormHelperText>
                </Box>
            )}
        />
    );
};

export default WiSelect;
