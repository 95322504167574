import { Controller, useFormContext } from "react-hook-form";

import { useFormMode } from "../../../hooks";
import { UploadAvatar } from "../../wi-upload";

import WiUploadAvatarProps from "./types/WiUploadAvatarProps";



const WiUploadAvatar: React.FC<WiUploadAvatarProps> = (props) => {

    const {
        name,
        ...other
    } = props;

    const { isView } = useFormMode();
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const checkError = !!error && !field.value ? error.message : undefined;

                return (
                    <UploadAvatar 
                        sx={{mt: '16px'}} 
                        error={checkError} 
                        {...other} 
                        file={field.value} 
                        disabled={other.disabled || isView}
                    />
                );
            }}
        />
    );
}


export default WiUploadAvatar;