import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, useTheme } from "@mui/material";

import useI18n from "../../hooks/useI18n";
import WiDialogViewImagesProps from "./@types/WiDialogViewImagesProps";
import { WiViewImagesCarousel } from "@wi-themes/minimal-override";
import Iconify from "@wi-themes/minimal/src/components/Iconify";


const WiDialogViewImages = (props: WiDialogViewImagesProps) => {

    const {
        title,
        open,
        onClose,
        images
    } = props;

    const { translate, lang } = useI18n();

    return <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown
    >
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <DialogTitle
                id="responsive-dialog-title"
                sx={{
                    p: 3,
                    height: '84px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                {title}

                <IconButton aria-label="upload picture" component="label" onClick={onClose}>
                    <Iconify icon={'eva:close-fill'} width={24} height={24} />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    py: 0,
                    height: '500px',
                    minWidth: '580px',
                    position: 'relative'
                }}
            >
                <WiViewImagesCarousel images={images} />
            </DialogContent>
            <DialogActions>
                <Button color="inherit" variant="outlined" onClick={onClose}>
                    {translate('common.close')}
                </Button>
            </DialogActions>
        </Box>
    </Dialog>
}

export default WiDialogViewImages;