import * as Yup from 'yup';

const useReservationCancelScheme = () => {
    const schema = Yup.object().shape({
        reason: Yup.object().required('common.error.required_field').nullable(),
        justification: Yup.string().required('common.error.required_field').nullable()
    });

    return {
        schema
    };
};

export default useReservationCancelScheme;
