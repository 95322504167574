// @mui
import { IconButton } from '@mui/material';

// components
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import MenuPopover from '@wi-themes/minimal/src/components/MenuPopover';

// types
import WiTableMoreMenuProps from './types/WiTableMoreMenuProps';

const WiTableMoreMenu = ({
    actions,
    open,
    onClose,
    onOpen,
    width,
    disabled,
    color
}: WiTableMoreMenuProps) => (
    <>
        <IconButton onClick={onOpen} disabled={disabled} sx={{color}}>
            <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
        </IconButton>

        <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            arrow="right-top"
            sx={{
                mt: -1,
                width: width || 183,
                '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                    '& svg': { mr: 2, width: 20, height: 20 },
                },
            }}
        >
            {actions}
        </MenuPopover>
    </>
);

export default WiTableMoreMenu;
