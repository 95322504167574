import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from "react-oidc-context";
import { I18nProvider, WiAuthGuard } from '@wi-core/ui';
import oidcConfig from '@wi-config/oidc';
import { CollapseDrawerProvider } from '@wi-themes/minimal/src/contexts/CollapseDrawerContext';
import { ProgressBarStyle } from '@wi-themes/minimal/src/components/ProgressBar';
import MotionLazyContainer from '@wi-themes/minimal/src/components/animate/MotionLazyContainer';
import { ThemeProvider, ThemeLocalizationProvider, DateLocalizationProvider } from '@wi-themes/minimal-override';
import WiSettings from '@wi-themes/minimal-override/src/components/wi-settings';

import i18n from './i18n';
import Router from './routes';
import colorScheme from './color-scheme';

const App = () => {
    return (
        <AuthProvider {...oidcConfig}>
            <I18nProvider resources={i18n}>
                <ThemeProvider colorScheme={colorScheme}>
                    <ThemeLocalizationProvider>
                        <DateLocalizationProvider>
                            <MotionLazyContainer>
                                <CollapseDrawerProvider>
                                    <BrowserRouter>
                                        <WiAuthGuard>
                                            <ProgressBarStyle />
                                            <WiSettings />
                                            <Router />
                                        </WiAuthGuard>
                                    </BrowserRouter>
                                </CollapseDrawerProvider>
                            </MotionLazyContainer>
                        </DateLocalizationProvider>
                    </ThemeLocalizationProvider>
                </ThemeProvider>
            </I18nProvider>
        </AuthProvider>
    );
}

export default App;
