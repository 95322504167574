import Env from '@wi-config/env';


const PATH_FILE: String = 'api/v1/files/public';


const FileApi = { 

    thumbnail: (token: string): string => {
        return `${Env.endpoint}/${PATH_FILE}/${token}/download`;
    }

};

export default FileApi;