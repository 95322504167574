import { useI18n } from '@wi-core/ui';
import { parse, isDate } from 'date-fns';

const DAYS_OF_WEEK = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
];
const MONTHS = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
];

const localeMap = {
    'en': 'mm/dd/yyyy',
    'pt_br': 'dd/mm/yyyy'
};

const useFormatDateTime = () => {
    const { lang } = useI18n();

    const fDateTime = (date: string, timeZone?: string) => {
        return new Date(date).toLocaleString(lang.language.replace('_', '-'), {
            timeZone
        });
    };

    const fDate = (date: string | Date, timeZone?: string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        return date.toLocaleDateString(lang.language.replace('_', '-'), {
            timeZone
        });
    };

    const fTime = (date: string | Date, timeStyle: 'short' | 'medium' = 'short', timeZone?: string, locales?: string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        return date.toLocaleTimeString(locales || lang.language.replace('_', '-'), {
            timeStyle,
            timeZone
        });
    };

    const fNewDate = (date?: Date | string | number | null, timeZone?: string, locales?: string) => {
        const newDate = date ? new Date(date) : new Date();

        return new Date(newDate.toLocaleString(locales || lang.language.replace('_', '-'), {
            timeZone
        }))
    }

    const fTimestamp = (date: string) => {
        return new Date(date).getTime();
    };

    const fTimestampStartDay = (date: Date | string) => {
         if (typeof date === 'string') {
            date = new Date(date);
        }

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date.getTime();
    };

    const fTimestampEndDay = (date: Date | string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(59);

        return date.getTime();
    };

    const fDateTimeStartDay = (date: Date | string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    };

    const fDateTimeEndDay = (date: Date | string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(59);

        return date;
    };

    const fDaysOfWeek = (date: string | Date) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        return DAYS_OF_WEEK[date.getDay()];
    };

    const fMonth = (date: Date | string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        return MONTHS[date.getMonth()];
    };

    const fDay = (date: Date | string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        const day = date.getDate();

        return day < 10 ? `0${day}` : day;
    };

    const fYear = (date: Date) => {
        const year = date.getFullYear();

        return year.toString().padStart(4, '0');
    };

    const fNumericMonth = (date: Date) => {
        const month = date.getMonth() + 1;

        return month < 10 ? `0${month}` : month;
    };

    const fNumericDayAndMonth = (date: Date) => {
        const day = fDay(date);
        const month = fNumericMonth(date);

        return `${day}/${month}`;
    };

    const fAgeByBirthDate = (birthDate: Date) => {
        const currentDate = new Date();
        var birthDateThisYear = new Date(
            currentDate.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
        );

        let age: number = currentDate.getFullYear() - birthDate.getFullYear();

        return fDateTimeStartDay(birthDateThisYear) >
            fDateTimeStartDay(currentDate)
            ? age - 1
            : age;
    };

    //@ts-ignore
    const parseDateString = (value, originalValue) => {
        if (!originalValue) {
            return null;
        }

        if (isDate(originalValue)) {
            return originalValue;
        }

        return parse(originalValue, localeMap[lang.language], new Date());
    };

    const fStringDateToLocalDate = (date: string) => {
        if (date == null || date == '') return null;

        const dateSplit = date.split('-');
        return new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2]));
    }

    const fDateIgnoringTimezone = (date?: Date | null) => {
        if (!date) return null;

        if (typeof date === 'string') {
            date = new Date(date);
        }

        return `${fYear(date)}-${fNumericMonth(date)}-${fDay(date)}`
    }

    const fMinutesToHoursMinutesAndSeconds = (minutes?: number | null) => {
        if (!minutes) return null;

        const newHours = minutes >= 60 ? (minutes / 60).toString().padStart(2, '0') : '00';
        const newMinutes = (minutes % 60).toString().padStart(2, '0');

        return `${newHours}:${newMinutes}:00`
    }

    const fHoursMinutesAndSeconds = (date?: Date | null) => {
        if (!date) return null;

        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
    }

    const fHoursAndMinutes = (date?: Date | null) => {
        if (!date) return null;

        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    }

    const addMinutes = (date: Date, minutes: number) => {
        return new Date(date.getTime() + (minutes * 60 * 1000));
    }

    // const fDateToFullCalendar = (date?: Date | string | number | null, timeZone?: string) => {
    //     const newDate = fNewDate(date, timeZone, 'en');

    //     return `${fDateIgnoringTimezone(newDate)}T${fHoursMinutesAndSeconds(newDate)}`;
    // }

    const getMinDate = () => {
        return new Date(1900, 0, 1);
    };

    return {
        fDateTime,
        fDate,
        fTime,
        fTimestamp,
        fTimestampStartDay,
        fTimestampEndDay,
        fDaysOfWeek,
        fMonth,
        fDay,
        fYear,
        fNumericDayAndMonth,
        fDateTimeStartDay,
        fDateTimeEndDay,
        fAgeByBirthDate,
        parseDateString,
        fStringDateToLocalDate,
        fDateIgnoringTimezone,
        fMinutesToHoursMinutesAndSeconds,
        fHoursMinutesAndSeconds,
        fHoursAndMinutes,
        addMinutes,
        fNewDate,
        getMinDate
    };
};

export default useFormatDateTime;
