import React, { useState } from 'react';
import { Divider, MenuItem, Avatar, useTheme } from '@mui/material';
import { IconButtonAnimate } from '@wi-themes/minimal/src/components/animate';
import WiAvatarService from '@wi-core/ui/src/services/wi-avatar/WiAvatar.service';
import { useAuth } from "react-oidc-context";
import { useI18n } from '@wi-core/ui';
import Iconify from '@wi-themes/minimal/src/components/Iconify';

import i18n from '../i18n';
import { useSettings } from '../../../../hooks';
import WiMenuPopover from '../../../../components/wi-menu-popover/WiMenuPopover';


export default function AccountPopover() {

    const auth = useAuth();
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const { lang } = useI18n();
    const theme = useTheme();
    const { onToggleSetting } = useSettings();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClose = () => {
        setOpenPopover(null);
    };

    const renderInitialsOrIcon = () => {
        if (auth.user?.profile.name) {
            return WiAvatarService.formatUsernameInitials(auth.user?.profile.name, 2);
        }

        return <Iconify icon={'eva:person-outline'} width={24} height={24} />;
    }

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(openPopover && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute'
                        },
                    }),
                }}
            >
                <Avatar sx={{ background: theme.palette.grey[500_12] }}>
                    {renderInitialsOrIcon()}
                </Avatar>
            </IconButtonAnimate>

            <WiMenuPopover
                anchorEl={openPopover}
                onClose={handleClose}
                arrowPosition="top-right"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    '.MuiPaper-root': {
                        minWidth: 180,
                        '& .MuiMenuItem-root': {
                            px: 1, 
                            typography: 'body2', 
                            borderRadius: 0.75 
                        }
                    }
                }}
            >
                <>
                    <MenuItem key={i18n[lang.language].settings} onClick={onToggleSetting}>
                        {i18n[lang.language].settings}
                    </MenuItem>

                    <Divider sx={{ borderStyle: 'dashed' }} />

                    <MenuItem onClick={() => auth.signoutRedirect()}>
                        {i18n[lang.language].logout}
                    </MenuItem>
                </>
            </WiMenuPopover>
        </>
    );
}
