import AppBrand from "../@types/AppBrand";

const APPS: AppBrand[] = [
    {
        key: 'portal',
        name: 'app.name.portal',
        shortDescription: 'app.description.portal',
        path: '/portal',
        icon: 'mdi:airplay'
    },
    // {
    //     key: 'demo',
    //     name: 'app.name.demo',
    //     shortDescription: 'app.description.demo',
    //     path: '/demo',
    //     icon: 'mdi:wall',
    //     color: '#103996'
    // },
    {
        key: 'schedule',
        name: 'app.name.schedule',
        shortDescription: 'app.description.schedule',
        path: '/schedule',
        icon: 'healthicons:i-schedule-school-date-time'
    },
    {
        key: 'planning',
        name: 'app.name.planning',
        shortDescription: 'app.description.planning',
        path: '/planner',
        icon: 'mdi:calendar-month-outline'
    },
    {
        key: 'management',
        name: 'app.name.management',
        shortDescription: 'app.description.management',
        path: '/management/license',
        icon: 'mdi:account-cog'
    },
    {
        key: 'attendance',
        name: 'app.name.attendance',
        shortDescription: 'app.description.attendance',
        path: '/attendance/attendance',
        icon: 'mdi:account-check'
    },
    {
        key: 'bi',
        name: 'app.name.bi',
        shortDescription: 'app.description.bi',
        path: '/bi',
        icon: 'mdi:chart-line'
    },
];

export default APPS;
