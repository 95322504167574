import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useI18n } from "@wi-core/ui";
import { WiAutocompleteOption, WiPhoneFieldDDI } from "@wi-themes/minimal-override";
import CountryApi from '../api/CountryApi';
import { Guid } from 'guid-typescript';

const useListCountryDDi = () => {
    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [listCountryDDI, setListCountryDDI] = useState<WiAutocompleteOption[]>([]);

    useEffect(() => {
        searchListDdi();
    }, []);

    const searchListDdi = () => {
        CountryApi.listDdi()
            .then((result) => {
                const { data } = result;

                const listDdi: WiAutocompleteOption[] = data.content.map((item: any) => {
                    return {
                        id: Guid.create().toString(),
                        label: item.name,
                        data: item
                    }
                });

                setListCountryDDI(listDdi);
            })
            .catch((error) => {
                enqueueSnackbar(
                    translate('common.search_error.error_loading_ddi'),
                    { variant: 'error' }
                );
            })
    }

    return {
        listCountryDDI
    };
}

export default useListCountryDDi;
