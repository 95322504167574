import { GlobalStyles, useTheme } from "@mui/material";



const CustomGlobalStyles = () => {

    const theme = useTheme();

    return (
        <GlobalStyles
            styles={{
                '.custom-z-index-wi-autocomplete': {
                    zIndex: `${theme.zIndex.modal + 1} !important`
                }
            }}
        />
    )
}

export default CustomGlobalStyles;