import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FormMode from './@types/FormMode';


const useFormMode = () => {

    const { pathname } = useLocation();

    const [mode, setMode] = useState<FormMode>('new');

    useEffect(() => {
        if(pathname.includes('view')) {
            return setMode('view');
        }

        if(pathname.includes('edit')) {
            return setMode('edit');
        }

        setMode('new');

    }, [pathname]);

    return {
        mode,
        changeMode: (newValue: FormMode) => {
            setMode(newValue);
        },
        isView: mode === 'view'
    };
}


export default useFormMode;