import * as Yup from 'yup';

const useReservationTransferScheme = () => {
    const schema = Yup.object().shape({
        user: Yup.object().required('common.error.required_field').nullable(),
        justification: Yup.string().required('common.error.required_field').nullable()
    });

    return {
        schema
    };
};

export default useReservationTransferScheme;
