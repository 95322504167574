import Iconify from "@wi-themes/minimal/src/components/Iconify";
import WiLabel from "../wi-label/WiLabel";
import WiTagProps from "./@types/WiTagProps";
import { IconButton } from "@mui/material";


const WiTag = (props: WiTagProps) => {

    const {
        color,
        label,
        sx,
        onDelete
    } = props;

    const addAlpha = (color: string, opacity: number): string => {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }

    return (
        <WiLabel
            sx={{
                ...sx,
                color: color,
                bgcolor : addAlpha(color, 0.16)
            }}
        >
            {label}
            {onDelete && <Iconify 
                icon={'eva:close-fill'} 
                height={16} 
                width={16} 
                onClick={onDelete}
                sx={{
                    ml: 0.5,
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.6
                    }
                }}
            />}
        </WiLabel>
    );
}

export default WiTag;
