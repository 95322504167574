import React, { useState } from 'react';
import { Card, Stack, Box, Button, CardContent, IconButton, Typography, Divider, useTheme } from '@mui/material';
import {
    FormProvider,
    WiAutocomplete,
    WiAutocompleteMultiple,
    WiDatePicker,
    WiProfessionalAutocomplete,
    WiSelect,
    WiSelectTags,
    WiTextField
} from '@wi-themes/minimal-override';
import { useI18n } from '@wi-core/ui';
import { AppointmentStatus, ReservationStatus, StatusFilter, TagClassification, useTag } from '@wi-core/shared-blocks';
import useReservationFilter from './hooks/useReservationFilter';
import ReservationFilterProps from './@types/ReservationFilterProps';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import i18n from '../../../../i18n';

const ReservationFilter = (props: ReservationFilterProps) => {
    const theme = useTheme();
    const { translate, lang } = useI18n();
    const { initialFilter, handleFilter } = props;

    const {
        organizationalUnitsToAutocomplete,
        loadingOrganizationalUnits,

        loadingLicenses,
        licensesToAutocomplete,

        professionalsToAutocomplete,
        loadingProfessionals,
        handleFilterProfessionalsByName,

        roomToAutocomplete,
        loadingRoom,
        handleFilterRoomByName,

        watchLicense,
        methods,
        watch,
        handleSubmit,

        tags,
        loadingTags,
        totalTags,
        handleFilterTagsByName,

        showFilter,
        handleShowFilter,

        usersToAutocomplete,
        loadingUsers,
        handleFilterUserByName
    } = useReservationFilter(initialFilter);

    const watchStartAt = watch('startAt');
    const watchEndAt = watch('endAt');


    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                paddingX={2}
                bgcolor="background.neutral"
                height={37}
            >
                <Box>
                    <IconButton size="small" onClick={handleShowFilter}>
                        <Iconify icon={showFilter ? 'oi:chevron-top' : 'oi:chevron-bottom'} />
                    </IconButton>
                </Box>
            </Stack>

            <Divider />
            <Box
                sx={{
                    height: showFilter ? 'auto' : '0px',
                    pt: showFilter ? 2.5 : '0px',
                    px: 3,
                    pb: showFilter ? 1 : '0px',
                    opacity: showFilter ? 1 : 0,
                    transition: theme.transitions.create(['opacity', 'height'], {
                        duration: theme.transitions.duration.shorter
                    })
                }}
            >
                <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(handleFilter)}
                    id="dialog-reservation-form-filter"
                >
                    <Stack spacing={2} direction="row">
                        <Box
                            sx={{
                                flex: 1,
                                display: 'grid',
                                columnGap: 2,
                                gridTemplateColumns: {
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(2, 1fr)',
                                    md: 'repeat(4, 1fr)'
                                }
                            }}
                        >
                            <WiAutocomplete
                                name="license"
                                label={translate('common.license')}
                                options={licensesToAutocomplete}
                                loading={loadingLicenses}
                            />

                            <WiAutocompleteMultiple
                                name="organizationalUnits"
                                label={translate('common.organizational_units')}
                                options={organizationalUnitsToAutocomplete}
                                loading={loadingOrganizationalUnits}
                            />

                            <WiProfessionalAutocomplete
                                name="professional"
                                label={translate('common.professional')}
                                options={professionalsToAutocomplete}
                                loading={loadingProfessionals}
                                disabled={watchLicense === null}
                                onInputChange={handleFilterProfessionalsByName}
                            />

                            <WiAutocomplete
                                name="room"
                                label={translate('common.room')}
                                options={roomToAutocomplete}
                                loading={loadingRoom}
                                onInputChange={handleFilterRoomByName}
                            />

                            <WiTextField
                                name="personName"
                                label={i18n[lang.language].person_name}
                                size="small"
                                type="text"
                            />

                            <WiDatePicker
                                name="personBirthDate"
                                label={i18n[lang.language].person_birth_date}
                                size="small"
                            />

                            <WiTextField
                                name="personDocument"
                                label={i18n[lang.language].person_document}
                                size="small"
                                type="text"
                            />

                            <WiAutocompleteMultiple
                                name="statuses"
                                label={translate('common.status')}
                                options={[
                                    {
                                        id: ReservationStatus.Reservation,
                                        label: translate(`common.reservation_status.${ReservationStatus.Reservation}`)
                                    },
                                    {
                                        id: ReservationStatus.Scheduling,
                                        label: translate(`common.reservation_status.${ReservationStatus.Scheduling}`)
                                    },
                                    {
                                        id: ReservationStatus.Cancelled,
                                        label: translate(`common.reservation_status.${ReservationStatus.Cancelled}`)
                                    },
                                    {
                                        id: ReservationStatus.Scheduled,
                                        label: translate(`common.reservation_status.${ReservationStatus.Scheduled}`)
                                    },
                                    {
                                        id: ReservationStatus.Expired,
                                        label: translate(`common.reservation_status.${ReservationStatus.Expired}`)
                                    }
                                ]}
                            />

                            <WiTextField
                                name="procedureName"
                                label={translate('appointment.list.procedure_name')}
                                size="small"
                                type="text"
                            />

                            <WiDatePicker
                                name="startAt"
                                label={translate('appointment.list.start_at')}
                                size="small"
                                maxDate={watchEndAt || undefined}
                            />

                            <WiDatePicker
                                name="endAt"
                                label={translate('appointment.list.end_at')}
                                size="small"
                                minDate={watchStartAt || undefined}
                            />

                            <WiSelectTags
                                name="tags"
                                label={translate('new_appointment.filter.field.tags')}
                                options={tags}
                                loading={loadingTags}
                                onInputChange={handleFilterTagsByName}
                                disabled={watchLicense === null}
                                totalOptions={totalTags}
                            />

                            <WiAutocomplete
                                name={'user'}
                                label={'Reservado por'}
                                options={usersToAutocomplete}
                                loading={loadingUsers}
                                onInputChange={handleFilterUserByName}
                            />

                            <Stack
                                direction="row-reverse"
                                sx={{
                                    marginTop: '3px',
                                    gridColumnStart: { md: 4, sx: 1, sm: 2 }
                                }}
                            >
                                <Button
                                    sx={{ height: '36px' }}
                                    type="submit"
                                    variant="contained"
                                    form="dialog-reservation-form-filter"
                                >
                                    {translate('appointment.list.filtrate')}
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                </FormProvider>
            </Box>
        </Card>
    );
};

export default ReservationFilter;
