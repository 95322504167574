import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    useTheme
} from '@mui/material';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import DialogReservationTransferProps from './@types/dialog-reservation-transfer-props/DialogReservationTransferProps';
import { FormProvider, WiAutocomplete, WiTextField } from '@wi-themes/minimal-override';
import i18n from './i18n';
import { SearchReservationResponse, useUser } from '@wi-core/shared-blocks';
import ReservationCancelFormType from './@types/reservation-transfer-form-type/ReservationTransferFormType';
import { useReservationTransferScheme, userReservationTransferManager } from './hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DefaultFormValues from './config/DefaultFormValues';
import useI18n from '../../hooks/useI18n';
import IsVisible from '../is-visible/IsVisible';
import WiReservationInfo from '../wi-reservation-info/WiReservationInfo';
import WiCircularLoading from '../wi-circular-loading/WiCircularLoading';
import WiFormCard from '../wi-form-card/WiFormCard';

const WiDialogReservationTransfer = (props: DialogReservationTransferProps) => {
    const { open, onClose, reservation } = props;

    const { translate, lang } = useI18n();

    const { usersToAutocomplete, loadingUsers, handleFilterUserByName } = useUser();

    const { onSubmit, loadingManager } = userReservationTransferManager(onClose);

    const { schema } = useReservationTransferScheme();

    const methods = useForm<ReservationCancelFormType>({
        resolver: yupResolver(schema),
        defaultValues: DefaultFormValues
    });

    const { handleSubmit } = methods;

    useEffect(() => {
        if (open) {
            methods.reset(DefaultFormValues);
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            fullScreen={false}
            disableEscapeKeyDown
            fullWidth
            maxWidth="xl"
        >
            <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    {i18n[lang.language].availability_reservation_transfer}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={onClose}>
                            <Iconify icon={'eva:close-fill'} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent
                sx={{
                    p: 3,
                    pt: '24px !important',
                    bgcolor: theme => theme.palette.grey[500_12]
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 2
                    }}
                >
                    <IsVisible when={!!reservation}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}
                        >
                            <WiReservationInfo row={reservation as SearchReservationResponse} showMenu={false} />
                        </Card>
                    </IsVisible>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}
                    >
                        <Box
                            sx={{
                                minHeight: '139px',
                                position: 'relative'
                            }}
                        >
                            <WiCircularLoading show={loadingManager} hideBorderRadius />
                            <FormProvider
                                methods={methods}
                                onSubmit={handleSubmit((data: ReservationCancelFormType) =>
                                    onSubmit(reservation?.id as string, reservation?.organizationalUnitKey as string, data)
                                )}
                                id="dialog-reservation-transfer-form"
                            >
                                <WiFormCard hideArchivedLabel={true}>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            columnGap: 2,
                                            rowGap: 2,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                columnGap: 2,
                                                rowGap: 2
                                            }}
                                        >
                                            <WiAutocomplete
                                                name="user"
                                                label={i18n[lang.language].field_user_to_transfer}
                                                options={usersToAutocomplete.filter(user => user.id !== reservation?.ownerId)}
                                                loading={loadingUsers}
                                                onInputChange={handleFilterUserByName}
                                                required
                                                sx={{ flex: 1 }}
                                            />

                                            <WiTextField
                                                name="justification"
                                                label={i18n[lang.language].field_description}
                                                size="small"
                                                required
                                                multiline={true}
                                                minRows={4}
                                                maxRows={4}
                                            />
                                        </Box>
                                    </Box>
                                </WiFormCard>
                            </FormProvider>
                        </Box>
                    </Card>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" variant="outlined" onClick={onClose}>
                    {translate('common.close')}
                </Button>
                <Button type="submit" variant="contained" form="dialog-reservation-transfer-form">
                    {translate('common.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WiDialogReservationTransfer;
