const ROLES = {
    SUPER_ADMIN: 'super_admin',
    ACCOUNTS_MANAGER: 'accounts_manager',
    ACCOUNTS_VIEWER: 'accounts_viewer',
    CONSENT_TERM_MANAGER: 'consent_term_manager',
    CONSENT_TERM_VIEWER: 'consent_term_viewer',
    HEALTH_PLAN_MANAGER: 'health_plan_manager',
    HEALTH_PLAN_VIEWER: 'health_plan_viewer',
    HEALTH_PLAN_IMPORTER: 'health_plan_importer',
    LICENSE_MANAGER: 'license_manager',
    LICENSE_VIEWER: 'license_viewer',
    NOTIFICATION_TEMPLATE_VIEWER: 'notification_template_viewer',
    NOTIFICATION_TEMPLATE_MANAGER: 'notification_template_manager',
    PERSON_VIEWER: 'person_viewer',
    PERSON_MANAGER: 'person_manager',
    PERSON_IMPORTER: 'person_importer',
    PLANNING_MANAGER: "planning_manager",
    PLANNING_VIEWER: "planning_viewer",
    PROCEDURE_MANAGER: 'procedure_manager',
    PROCEDURE_VIEWER: 'procedure_viewer',
    PROCEDURE_IMPORTER: 'procedure_importer',
    PROFESSIONAL_MANAGER: 'professional_manager',
    PROFESSIONAL_VIEWER: 'professional_viewer',
    PROFESSIONAL_IMPORTER: "professional_importer",
    ROOM_VIEWER: 'room_viewer',
    ROOM_MANAGER: 'room_manager',
    ROOM_IMPORTER: 'room_importer',
    SCHEDULE_SCHEDULER: 'schedule_scheduler',
    SCHEDULE_MANAGER: 'schedule_manager',
    SCHEDULE_SCHEDULER_SPECIAL: 'schedule_scheduler_special',
    SPECIALITY_MANAGER: 'speciality_manager',
    SPECIALITY_VIEWER: 'speciality_viewer',
    SPECIALITY_IMPORTER: 'speciality_importer',
    ATTENDANCE_MANAGER: 'attendance_manager',
    ATTENDANCE_VIEWER: 'attendance_viewer',
    BI_REPORT_TEMPLATE_VIEWER: 'bi_report_template_viewer',
    BI_REPORT_TEMPLATE_MANAGER: 'bi_report_template_manager',
    COLLECTION_MATERIAL_MANAGER: 'collection_material_manager',
    COLLECTION_MATERIAL_VIEWER: 'collection_material_viewer',
    COLLECTION_MATERIAL_IMPORTER: 'collection_material_importer',
    SECTOR_MANAGER: 'sector_manager',
    SECTOR_VIEWER: 'sector_viewer',
    SECTOR_IMPORTER: 'sector_importer',
    BI_DASHBOARD_MANAGER:"bi_dashboard_manager",
    BI_DASHBOARD_VIEWER: "bi_dashboard_viewer",
    BI_DASHBOARD_RENDERER: "bi_dashboard_renderer",
    REASON_VIEWER: "reason_viewer",
    REASON_MANAGER: "reason_manager",
    RESERVATION_VIEWER: "reservation_viewer",
    RESERVATION_MANAGER: "reservation_manager",
    SPECIAL_RULES_VIEWER: "special_rules_viewer",
    SPECIAL_RULES_MANAGER: "special_rules_manager",
}

export default ROLES;
