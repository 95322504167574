

let debounceTimer: ReturnType<typeof setTimeout>;

const useDebounce = () => {

    const debounce = (callback: VoidFunction, delay?: number) => {
        clearTimeout(debounceTimer);

        debounceTimer = setTimeout(() => {
            callback();
        }, delay || 500)
    }

    return {
        debounce
    }
}

export default useDebounce;
