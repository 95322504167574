import { Typography } from "@mui/material";
import Iconify from "@wi-themes/minimal/src/components/Iconify";
import { 
    Timeline, 
    TimelineConnector, 
    TimelineContent, 
    TimelineDot, 
    TimelineItem, 
    TimelineOppositeContent, 
    TimelineSeparator 
} from "@mui/lab";

import useI18n from "../../hooks/useI18n";

import i18n from "./i18n";

import WiDataEventHistoryProps from "./@types/WiDataEventHistoryProps";
import { useFormatDateTime } from "@wi-themes/minimal-override";



const TIMELINE_ITEM_ICON = {
    'new': 'eva:plus-fill',
    'edit': 'eva:edit-fill',
    'archived': 'eva:archive-outline'
}

const WiDataEventHistory = (props: WiDataEventHistoryProps) => {

    const {
        created,
        createdBy,

        archived,
        archivedBy,

        updated,
        updatedBy
    } = props;

    const { lang } = useI18n();
    const {
        fDate,
        fTime
    } = useFormatDateTime();

    const renderTimelineItem = (date: string | null, name: string | null, type: 'new' | 'edit' | 'archived') => {

        if(date === null || name === null) return;

        return <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0', maxWidth: '150px' }}
                align="right"
                variant="caption"
                color="text.secondary"
            >
                {fDate(date)} <br/>
                {fTime(date)}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                    <Iconify icon={TIMELINE_ITEM_ICON[type]} width={20} height={20} />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ my: '16px' }}>
                <Typography variant="subtitle1" component="span">
                    {i18n[lang.language][type]}
                </Typography>
                <Typography>{name}</Typography>
            </TimelineContent>
        </TimelineItem>
    }

    return (
        <Timeline>
            {renderTimelineItem(created, createdBy, 'new')}
            {renderTimelineItem(updated, updatedBy, 'edit')}
            {renderTimelineItem(archived, archivedBy, 'archived')}
        </Timeline>
    );
}


export default WiDataEventHistory;