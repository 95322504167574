import { AxiosResponse } from 'axios';
import api from '@wi-config/axios';
import { PaginationResponse } from '@wi-core/shared-blocks';

import SearchRoomRequest from '../@types/search-room/SearchRoomRequest';
import SearchRoomResponse from '../@types/search-room/SearchRoomResponse';
import SearchRoomByCalendarTypeRequest from '../@types/search-room-by-calendar-type/SearchRoomByCalendarTypeRequest';
import SearchRoomByCalendarTypeResponse from '../@types/search-room-by-calendar-type/SearchRoomByCalendarTypeResponse';


const PATH_ROOM: string = 'api/v1/planning/rooms';

const RoomApi = {
    search: (data?: SearchRoomRequest): Promise<AxiosResponse<PaginationResponse<SearchRoomResponse>>> => {
        return api.get(`${PATH_ROOM}/search`, { params: data });
    },
    searchByCalendarType: (data?: SearchRoomByCalendarTypeRequest): Promise<AxiosResponse<PaginationResponse<SearchRoomByCalendarTypeResponse>>> => {
        return api.get(`${PATH_ROOM}/searchbycalendartype`, { params: data });
    }
};

export default RoomApi;
