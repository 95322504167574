import { Box, FormHelperText } from "@mui/material";
import { useDebounce, useI18n } from "@wi-core/ui";
import { useFormContext } from "react-hook-form";

import WiSelectTagsOption from "../../wi-inputs-no-form/wi-select-tags-no-form/@types/WiSelectTagsOption";
import WiSelectTagsNoForm from "../../wi-inputs-no-form/wi-select-tags-no-form/WiSelectTagsNoForm";
import { useFormMode } from "../../../hooks";
import WiSelectTagsProps from "./@types/WiSelectTagsProps";


const WiSelectTags = (props: WiSelectTagsProps) => {

    const {
        name,
        label,
        placeholder,
        options,
        onInputChange,
        inputChangeDebounceTime,
        loading,
        disabled,
        required,
        sx,
        totalOptions
    } = props;

    const { isView } = useFormMode();
    const { translate } = useI18n();

    const { getValues, setValue, getFieldState, formState, trigger } = useFormContext();

    const fieldState = getFieldState(name, formState);
    const currentValue = getValues(name);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
            <WiSelectTagsNoForm
                label={label}
                error={!!fieldState.error}
                value={currentValue || []}
                options={options}
                loading={loading}
                required={required}
                disabled={disabled || isView}
                placeholder={placeholder}
                inputChangeDebounceTime={inputChangeDebounceTime}
                onChange={(values: WiSelectTagsOption[]) => {
                    setValue(name, values);
                    trigger(name);
                }}
                onInputChange={onInputChange}
                totalOptions={totalOptions}
            />

            <FormHelperText error sx={{ m: '4px 8px 0 8px', minHeight: '8px' }}>
                {translate(fieldState.error?.message || '')}
            </FormHelperText>
        </Box>
    );
}


export default WiSelectTags;
