import React from "react";
import { Card } from "@mui/material";

import WiArchivedLabel from "../wi-archived-label/WiArchivedLabel";

import WiFormCardProps from "./@types/WiFormCardProps";


const WiFormCard = (props: WiFormCardProps) => {

    const {
        sx,
        hideArchivedLabel,
        children
    } = props;


    return (
        <Card sx={{
            p: 3,
            pb: '12px',
            position: 'relative',
            ...sx
        }}>
            <WiArchivedLabel hide={hideArchivedLabel}/>

            {children}
        </Card>
    );
}

export default WiFormCard;