import { AxiosResponse } from 'axios';
import api from '@wi-config/axios';

import CreateReservationRequest from '../@types/create-reservation/CreateReservationRequest';
import { CommonCreateUpdateResponse } from '../../common-create-update-response';
import { PaginationResponse } from '../../pagination';
import { CancelReservationRequest, SearchReservationRequest, SearchReservationResponse, ReservationTransferRequest } from '../@types';
import GetReservationResponse from '../@types/get-reservation/GetReservationResponse';

const PATH_RESERVATION: string = 'api/v1/schedule';

const ReservationApi = {
    create: (data: CreateReservationRequest, signal?: AbortSignal): Promise<AxiosResponse<CommonCreateUpdateResponse>> => {
        return api.post(`${PATH_RESERVATION}/reservation`, data, { signal });
    },
    search: (
        data?: SearchReservationRequest,
        signal?: AbortSignal
    ): Promise<AxiosResponse<PaginationResponse<SearchReservationResponse>>> => {
        return api.get(`${PATH_RESERVATION}/reservation/search`, { params: data, signal });
    },
    cancel: (id: string, organizationalUnitKey: string, data: CancelReservationRequest): Promise<AxiosResponse<CommonCreateUpdateResponse>> => {
        return api.put(`${PATH_RESERVATION}/${organizationalUnitKey}/reservation/${id}/cancel`, data);
    },
    transfer: (id: string, organizationalUnitKey: string, data: ReservationTransferRequest): Promise<AxiosResponse<CommonCreateUpdateResponse>> => {
        return api.put(`${PATH_RESERVATION}/${organizationalUnitKey}/reservation/${id}/transfer`, data);
    },
    get: (id: string, organizationalUnitKey: string): Promise<AxiosResponse<GetReservationResponse>> => {
        return api.get(`${PATH_RESERVATION}/${organizationalUnitKey}/reservation/${id}`);
    }
};

export default ReservationApi;
