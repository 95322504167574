import { useState } from 'react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import ReasonApi from '../../api/ReasonApi';
import { PaginationResponse } from '../../../pagination';
import { SearchReasonRequest, SearchReasonResponse } from '../../@types';
import { ErrorDetail } from '../../../error';

const useReasonQuery = () => {
    const reasonSearchQuery = (request: SearchReasonRequest | null): UseQueryResult<PaginationResponse<SearchReasonResponse>, ErrorDetail> => {
        return useQuery({
            queryKey: ['reasonSearchQuery', request],
            enabled: !!request,
            queryFn: async ({ signal }) => {
                return ReasonApi.search(request as SearchReasonRequest, signal);
            }
        });
    }

    return {
        reasonSearchQuery
    };
};
export default useReasonQuery;
