import { add, format } from 'date-fns';


const useDateTimeTz = () => {

    const fDateTime = (refDate?: Date | string, newFormat?: string) => {
        if (typeof refDate === 'string') {
            refDate = new Date(refDate);
        }
        return format((refDate || new Date()), newFormat || "yyyy-MM-dd HH:mm:ss");
    }

    const fDateToFullCalendar = (refDate?: Date | string) => {
        if (typeof refDate === 'string') {
            refDate = new Date(refDate);
        }
        return format((refDate || new Date()), "yyyy-MM-dd'T'HH:mm:ss");
    }

    const fYearMonthAndDay = (refDate?: Date | string) => {
        if (typeof refDate === 'string') {
            refDate = new Date(refDate);
        }
        return format((refDate || new Date()), "yyyy-MM-dd");
    }

    const getDateWithOffset = (refDate: Date, offset: string | null) => {
        const date = (refDate || new Date());

        if (!offset) {
            offset = getLocalOffset();
        }

        const unitOffset = parseInt(offset.split(':')[0]);
        const localOffset = date.getTimezoneOffset() / 60;

        return add(date, { hours: unitOffset + localOffset });
    }

    const revertDateWithOffsetToLocal = (refDate: Date, offset: string) => {
        const date = (refDate || new Date());
        const unitOffset = parseInt(offset.split(':')[0]);
        const localOffset = date.getTimezoneOffset() / 60;

        return add(date, { hours: ((unitOffset + localOffset) * -1) });
    }

    const getLocalOffset = () => {
        const localOffset = new Date().getTimezoneOffset() / 60;
        const hour = localOffset < 0 ? localOffset * -1 : localOffset;
        const signal = localOffset > 0 ? '-' : '';

        return `${signal}${hour.toString().padStart(2, '0')}:00`;
    }

    return {
        fDateTime,
        fDateToFullCalendar,
        fYearMonthAndDay,
        getDateWithOffset,
        revertDateWithOffsetToLocal,
        getLocalOffset
    }
}

export default useDateTimeTz;
