import { useEffect, useState } from "react";
import { useI18n } from "@wi-core/ui";
import { useSnackbar } from "notistack";
import { WiAutocompleteOption } from "@wi-themes/minimal-override";

import HealthPlanApi from "../../api/HealthPlanApi";
import { PaginationDefaultValues } from "../../../pagination";
import { SearchHealthPlanRequest } from "../../@types";
import UseHealthPlanToAutocompleteProps from "./@types/UseHealthPlanToAutocompleteProps";


const useHealthPlanToAutocomplete = (props: UseHealthPlanToAutocompleteProps) => {

    const {
        licenseRequired,
        licenseKey,
        hideHealthCardNotRequired,
        status
    } = props;

    const { translate } = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [healthPlans, setHealthPlans] = useState<WiAutocompleteOption[]>([]);
    const [loadingHealthPlans, setLoadingHealthPlans] = useState<boolean>(false);
    const [filterByName, setFilterByName] = useState<string>('');

    useEffect(() => {
        searchHealthPlans();
    }, [licenseKey]);

    const searchHealthPlans = (search?: SearchHealthPlanRequest) => {

        if (licenseRequired && licenseKey == null) return;

        setLoadingHealthPlans(true);

        search = search || {
            keyword: filterByName,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            licensesKey: licenseKey,
            hideHealthCardNotRequired,
            status
        };

        HealthPlanApi.search(
            search
        ).then((result) => {
            const { data: { content } } = result;

            setHealthPlans(content.map(healthPlan => {
                return {
                    id: healthPlan.id,
                    label: healthPlan.name,
                    licenseKey: healthPlan.licenseKey,
                    data: healthPlan
                };
            }));
        }).catch((error) => {
            setHealthPlans([]);

            enqueueSnackbar(
                translate('common.search_error.error_loading_health_plans'),
                { variant: 'error' }
            );
        }).finally(() => {
            setLoadingHealthPlans(false);
        })
    }

    const handleFilterHealthPlansByName = (value: string) => {
        setFilterByName(value);

        const search: SearchHealthPlanRequest = {
            keyword: value,
            page: PaginationDefaultValues.PAGE,
            size: PaginationDefaultValues.SIZE,
            sort: PaginationDefaultValues.SORT,
            licensesKey: licenseKey,
            hideHealthCardNotRequired,
            status: status
        };

        searchHealthPlans(search);
    }

    return {
        healthPlans,
        loadingHealthPlans,
        handleFilterHealthPlansByName
    }
}

export default useHealthPlanToAutocomplete;
